.App {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  width: 100%;
  flex: 1 0 auto;
}
