.our-company-page {
  padding-top: 0;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
}

.hero-section {
  height: 100vh;
  background: linear-gradient(45deg, rgba(11, 28, 61, 0.95), rgba(0, 0, 0, 0.85));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, 
    rgba(207, 180, 131, 0.05) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(207, 180, 131, 0.05) 50%, 
    rgba(207, 180, 131, 0.05) 75%, 
    transparent 75%, 
    transparent
  );
  background-size: 60px 60px;
  opacity: 0.1;
  animation: backgroundFloat 30s linear infinite;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 5rem;
  margin-bottom: 1.5rem;
  font-weight: 200;
  letter-spacing: 12px;
  line-height: 1.2;
  background: linear-gradient(45deg, #fff, #CFB483);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease forwards 0.5s;
}

.hero-content h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: 300;
  letter-spacing: 4px;
  color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease forwards 0.7s;
}

.hero-quote {
  max-width: 900px;
  margin: 0 auto;
  font-style: italic;
  line-height: 1.8;
  position: relative;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease forwards 0.9s;
}

.hero-quote::before,
.hero-quote::after {
  content: '"';
  font-size: 4rem;
  color: #CFB483;
  position: absolute;
  opacity: 0.3;
}

.hero-quote::before {
  top: -20px;
  left: -20px;
}

.hero-quote::after {
  bottom: -40px;
  right: -20px;
}

.hero-quote p {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.85);
  padding: 0 40px;
}

.hero-quote span {
  font-size: 1.1rem;
  color: #CFB483;
  font-weight: 400;
  letter-spacing: 2px;
  display: block;
  margin-top: 20px;
}

.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: fadeIn 1s ease forwards 1.5s;
}

.mouse {
  width: 30px;
  height: 50px;
  border: 2px solid rgba(207, 180, 131, 0.4);
  border-radius: 15px;
  position: relative;
}

.wheel {
  width: 3px;
  height: 10px;
  background: #CFB483;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  animation: scroll 1.5s ease infinite;
  opacity: 0.75;
}

.vision-section {
  padding: 150px 20px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.vision-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(207, 180, 131, 0.3), transparent);
}

.vision-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.elegant-text {
  font-size: 1.6rem;
  line-height: 2;
  color: #0b1c3d;
  font-weight: 300;
  letter-spacing: 1px;
  position: relative;
  padding: 0 40px;
}

.elegant-text::before,
.elegant-text::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 100px;
  background: linear-gradient(to bottom, #CFB483, transparent);
}

.elegant-text::before {
  left: 0;
  top: 0;
}

.elegant-text::after {
  right: 0;
  bottom: 0;
  transform: rotate(180deg);
}

.image-gallery {
  padding: 100px 20px;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.gallery-grid {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 250px;
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.1);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.gallery-item:first-child {
  grid-column: span 7;
  grid-row: span 2;
}

.gallery-item:nth-child(2) {
  grid-column: span 5;
  grid-row: span 1;
}

.gallery-item:nth-child(3) {
  grid-column: span 5;
  grid-row: span 1;
}

.gallery-item:nth-child(4) {
  grid-column: span 12;
  grid-row: span 1;
  height: 300px;
}

.gallery-item:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(11, 28, 61, 0.95));
  padding: 40px;
  color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease;
}

.gallery-item:hover .overlay {
  opacity: 1;
  transform: translateY(0);
}

.overlay h3 {
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 3px;
  margin: 0;
  position: relative;
  padding-bottom: 15px;
}

.overlay h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: #CFB483;
}

.about-section {
  padding: 150px 20px;
  background-color: #fff;
  position: relative;
}

.about-content {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  position: relative;
}

.about-content::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: linear-gradient(to bottom, transparent, rgba(207, 180, 131, 0.3), transparent);
}

.text-column {
  padding: 40px;
  position: relative;
}

.text-column h2 {
  font-size: 2.8rem;
  color: #0b1c3d;
  margin-bottom: 2rem;
  font-weight: 200;
  letter-spacing: 3px;
  position: relative;
  padding-bottom: 20px;
}

.text-column h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, #CFB483, transparent);
}

.text-column p {
  font-size: 1.2rem;
  line-height: 2;
  color: #555;
  margin-bottom: 2rem;
  font-weight: 300;
}

.cta-section {
  padding: 180px 20px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(11, 28, 61, 0.95), rgba(0, 0, 0, 0.85));
}

.cta-content {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.cta-content h2 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  font-weight: 200;
  letter-spacing: 6px;
  background: linear-gradient(45deg, #fff, #CFB483);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-content p {
  font-size: 1.6rem;
  margin-bottom: 3rem;
  font-weight: 300;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.9);
}

.cta-button {
  display: inline-block;
  padding: 1.5rem 4rem;
  background: transparent;
  border: 2px solid #CFB483;
  color: #CFB483;
  font-size: 1.2rem;
  text-decoration: none;
  letter-spacing: 3px;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #CFB483;
  transform: translateX(-100%);
  transition: transform 0.4s ease;
  z-index: -1;
}

.cta-button:hover {
  color: #0b1c3d;
}

.cta-button:hover::before {
  transform: translateX(0);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(-50%) translateY(0);
    opacity: 0.75;
  }
  50% {
    transform: translateX(-50%) translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%) translateY(0);
    opacity: 0.75;
  }
}

@keyframes backgroundFloat {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
}

@media (max-width: 1400px) {
  .gallery-grid {
    grid-template-columns: repeat(12, 1fr);
  }
  
  .gallery-item:first-child {
    grid-column: span 6;
    grid-row: span 2;
  }
  
  .gallery-item:nth-child(2) {
    grid-column: span 6;
    grid-row: span 1;
  }
  
  .gallery-item:nth-child(3) {
    grid-column: span 6;
    grid-row: span 1;
  }
  
  .gallery-item:nth-child(4) {
    grid-column: span 12;
  }
}

@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 4rem;
  }
  
  .hero-content h2 {
    font-size: 1.8rem;
  }
  
  .about-content {
    gap: 60px;
  }

  .text-column h2 {
    font-size: 2.4rem;
  }
}

@media (max-width: 992px) {
  .gallery-grid {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 200px;
  }
  
  .gallery-item:first-child {
    grid-column: span 6;
    grid-row: span 1;
  }
  
  .gallery-item:nth-child(2) {
    grid-column: span 3;
    grid-row: span 1;
  }
  
  .gallery-item:nth-child(3) {
    grid-column: span 3;
    grid-row: span 1;
  }
  
  .gallery-item:nth-child(4) {
    grid-column: span 6;
    height: 250px;
  }
  
  .overlay {
    padding: 20px;
  }
  
  .overlay h3 {
    font-size: 1.6rem;
  }

  .about-content {
    grid-template-columns: 1fr;
    gap: 80px;
  }

  .about-content::before {
    display: none;
  }

  .text-column {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 3rem;
    letter-spacing: 8px;
  }
  
  .hero-content h2 {
    font-size: 1.4rem;
  }
  
  .hero-quote p {
    font-size: 1.2rem;
  }
  
  .elegant-text {
    font-size: 1.3rem;
  }
  
  .gallery-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 15px;
  }
  
  .gallery-item {
    grid-column: span 1 !important;
    height: 250px !important;
  }
  
  .gallery-item:first-child {
    height: 300px !important;
  }
  
  .cta-content h2 {
    font-size: 2.8rem;
  }
  
  .cta-content p {
    font-size: 1.3rem;
  }

  .vision-section,
  .about-section {
    padding: 100px 20px;
  }
} 