/* FundingSolutions.css */
.funding-solutions {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

/* Hero Section Styles */
.funding-hero-section {
  position: relative;
  height: 90vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.designer-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #001233;
  z-index: 0;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 18, 51, 0.9) 0%, rgba(0, 25, 70, 0.7) 100%);
  z-index: 1;
}

.pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23cfb483' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  z-index: 2;
}

.light-effect {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(ellipse at center, rgba(207, 180, 131, 0.05) 0%, rgba(0, 18, 51, 0) 70%);
  z-index: 3;
}

.line-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(207, 180, 131, 0.1) 1px, transparent 1px),
                    linear-gradient(to bottom, rgba(207, 180, 131, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  z-index: 4;
}

.animated-shapes .shape {
  position: absolute;
  z-index: 5;
  opacity: 0.03;
  border: 1px solid #cfb483;
}

.shape-1 {
  top: 20%;
  left: 15%;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  animation: float 20s infinite ease-in-out;
}

.shape-2 {
  top: 50%;
  right: 10%;
  width: 200px;
  height: 200px;
  transform: rotate(45deg);
  animation: float 25s infinite ease-in-out reverse;
}

.shape-3 {
  bottom: 15%;
  left: 30%;
  width: 150px;
  height: 150px;
  transform: rotate(30deg);
  animation: float 15s infinite ease-in-out 2s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-20px) rotate(5deg);
  }
  50% {
    transform: translateY(10px) rotate(-5deg);
  }
  75% {
    transform: translateY(-15px) rotate(3deg);
  }
}

.hero-particles .particle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(207, 180, 131, 0.3);
  z-index: 6;
}

.particle:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: twinkle 6s infinite ease-in-out;
}

.particle:nth-child(2) {
  top: 40%;
  right: 25%;
  animation: twinkle 8s infinite ease-in-out 1s;
}

.particle:nth-child(3) {
  bottom: 30%;
  left: 30%;
  animation: twinkle 7s infinite ease-in-out 2s;
}

.particle:nth-child(4) {
  top: 70%;
  right: 40%;
  animation: twinkle 9s infinite ease-in-out 3s;
}

.particle:nth-child(5) {
  top: 85%;
  left: 60%;
  animation: twinkle 5s infinite ease-in-out 4s;
}

@keyframes twinkle {
  0%, 100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
}

.funding-hero-content {
  position: relative;
  z-index: 10;
  text-align: center;
  color: white;
  padding: 0 20px;
  max-width: 1000px;
}

.funding-hero-content h1 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 6px;
  margin-bottom: 10px;
  color: #cfb483;
}

.funding-hero-content h2 {
  font-size: 60px;
  font-weight: 700;
  margin: 0 0 20px;
  background: linear-gradient(to right, #ffffff, #cfb483);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.funding-hero-content h3 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
  color: #e5e5e5;
}

.funding-cta-button {
  background: linear-gradient(135deg, #cfb483, #a8935a);
  color: white;
  border: none;
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.funding-cta-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  transform: translateX(-100%);
  transition: transform 0.6s;
}

.funding-cta-button:hover::after {
  transform: translateX(100%);
}

/* Introduction Section Styles */
.funding-introduction {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 10%;
  position: relative;
  background-color: #f9f9f9;
}

.subtle-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23cfb483' fill-opacity='0.03' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  pointer-events: none;
}

.intro-content {
  flex: 1 1 550px;
  padding-right: 40px;
}

.intro-image {
  flex: 1 1 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-image img {
  width: 100%;
  max-width: 550px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
}

.intro-content h2 {
  font-size: 34px;
  font-weight: 600;
  color: #001233;
  margin-bottom: 15px;
}

.gold-separator {
  width: 80px;
  height: 3px;
  background: linear-gradient(to right, #cfb483, #a8935a);
  margin-bottom: 25px;
}

.intro-content p {
  font-size: 16px;
  line-height: 1.8;
  color: #333;
  margin-bottom: 20px;
}

.highlighted-text {
  padding: 20px;
  border-left: 3px solid #cfb483;
  background-color: rgba(207, 180, 131, 0.05);
  font-style: italic;
}

/* Solutions Section Styles */
.solutions-section {
  padding: 100px 5%;
  position: relative;
  text-align: center;
  background-color: #fff;
}

.solutions-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23cfb483' fill-opacity='0.02'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z'/%3E%3C/g%3E%3C/svg%3E");
  pointer-events: none;
}

.section-title {
  font-size: 36px;
  font-weight: 600;
  color: #001233;
  margin-bottom: 60px;
  position: relative;
  display: inline-block;
  z-index: 2;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(to right, #cfb483, #a8935a);
}

.solutions-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.solution-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 35px 25px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.card-accent {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, #cfb483, #a8935a);
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s ease;
}

.solution-card:hover .card-accent {
  transform: scaleX(1);
}

.solution-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(135deg, #001233, #002366);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: #cfb483;
  font-size: 26px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.solution-card h3 {
  font-size: 20px;
  font-weight: 600;
  color: #001233;
  margin-bottom: 15px;
}

.solution-card p {
  font-size: 15px;
  line-height: 1.7;
  color: #555;
  flex-grow: 1;
}

/* Funding Process Section Styles */
.funding-process-section {
  position: relative;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  overflow: hidden;
}

.process-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.process-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.process-background .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 18, 51, 0.85) 0%, rgba(0, 25, 70, 0.85) 100%);
}

.process-content {
  position: relative;
  z-index: 10;
  max-width: 1000px;
  padding: 50px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}

.process-content h2 {
  font-size: 32px;
  font-weight: 600;
  color: #001233;
  margin-bottom: 40px;
  text-align: center;
}

.process-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 25px;
  margin-bottom: 40px;
}

.process-step {
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 40px 20px 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.step-number {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #cfb483, #a8935a);
  color: white;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.step-content h3 {
  font-size: 18px;
  font-weight: 600;
  color: #001233;
  margin-bottom: 15px;
}

.step-content p {
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.contact-link {
  display: block;
  background: linear-gradient(135deg, #cfb483, #a8935a);
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 30px;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
}

.contact-link:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .funding-hero-content h2 {
    font-size: 50px;
  }
  
  .funding-hero-content h3 {
    font-size: 20px;
  }
  
  .process-content {
    padding: 40px 30px;
  }
}

@media (max-width: 992px) {
  .funding-hero-content h2 {
    font-size: 40px;
  }
  
  .intro-content {
    flex: 1 1 100%;
    padding-right: 0;
    margin-bottom: 40px;
  }
  
  .intro-image {
    flex: 1 1 100%;
  }
  
  .solutions-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  .process-steps {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .funding-process-section {
    padding: 80px 0;
  }
}

@media (max-width: 768px) {
  .funding-hero-section {
    height: 80vh;
  }
  
  .funding-hero-content h1 {
    font-size: 16px;
  }
  
  .funding-hero-content h2 {
    font-size: 34px;
  }
  
  .funding-hero-content h3 {
    font-size: 18px;
  }
  
  .solutions-section {
    padding: 60px 5%;
  }
  
  .section-title {
    font-size: 30px;
    margin-bottom: 40px;
  }
  
  .intro-content h2 {
    font-size: 28px;
  }
  
  .process-content {
    padding: 30px 20px;
  }
  
  .process-content h2 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  
  .process-steps {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .funding-hero-content h2 {
    font-size: 28px;
  }
  
  .funding-cta-button {
    padding: 12px 25px;
    font-size: 14px;
  }
  
  .funding-introduction {
    padding: 60px 5%;
  }
  
  .solution-card {
    padding: 25px 15px;
  }
  
  .step-number {
    width: 40px;
    height: 40px;
    font-size: 20px;
    top: -20px;
  }
  
  .process-step {
    padding: 30px 15px 20px;
  }
  
  .contact-link {
    padding: 12px 20px;
    font-size: 14px;
  }
}