/* 住宅项目管理页面高级样式 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

.project-management {
  width: 100%;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  color: #222;
  background-color: #fafafa;
  overflow-x: hidden;
}

/* 英雄部分 - 创新3D网格背景 */
.management-hero-section {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.grid-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-content-wrapper {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 900px;
  padding: 0 20px;
}

.management-hero-content {
  color: white;
  position: relative;
}

.management-hero-content h1 {
  font-size: 3.8rem;
  font-weight: 300;
  letter-spacing: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
  background: linear-gradient(135deg, #fff, #CFB483);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.hero-separator {
  width: 120px;
  height: 1px;
  background: linear-gradient(90deg, 
    rgba(207, 180, 131, 0) 0%, 
    rgba(207, 180, 131, 1) 50%, 
    rgba(207, 180, 131, 0) 100%);
  margin: 30px auto;
}

.management-hero-content h2 {
  font-size: 2.2rem;
  font-weight: 300;
  margin-bottom: 30px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.4;
}

.management-hero-content h3 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #CFB483;
  letter-spacing: 4px;
  margin-top: 40px;
  text-transform: uppercase;
}

/* 装饰性线条和形状 */
.hero-overlay-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.vertical-line {
  position: absolute;
  width: 1px;
  background: linear-gradient(to bottom, 
    rgba(207, 180, 131, 0) 0%, 
    rgba(207, 180, 131, 0.2) 50%, 
    rgba(207, 180, 131, 0) 100%);
}

.horizontal-line {
  position: absolute;
  height: 1px;
  background: linear-gradient(to right, 
    rgba(207, 180, 131, 0) 0%, 
    rgba(207, 180, 131, 0.2) 50%, 
    rgba(207, 180, 131, 0) 100%);
}

.diagonal-line {
  position: absolute;
  height: 1px;
  background: rgba(207, 180, 131, 0.1);
  transform-origin: left center;
}

.line-1 {
  top: 10%;
  bottom: 10%;
  left: 15%;
}

.line-2 {
  top: 10%;
  bottom: 10%;
  right: 15%;
}

.line-3 {
  top: 75%;
  left: 10%;
  right: 10%;
}

.line-4 {
  top: 30%;
  left: 5%;
  width: 40%;
  transform: rotate(45deg);
}

.circle-element {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 1px solid rgba(207, 180, 131, 0.1);
  top: 20%;
  right: 10%;
}

.square-element {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid rgba(207, 180, 131, 0.1);
  bottom: 15%;
  left: 10%;
  transform: rotate(45deg);
}

/* 向下滚动指示器 */
.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: #CFB483;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 10;
}

/* 介绍部分 */
.management-intro-section {
  padding: 100px 0;
  background-color: #fafafa;
  position: relative;
}

.management-intro-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, 
    rgba(207, 180, 131, 0) 0%, 
    rgba(207, 180, 131, 0.3) 50%, 
    rgba(207, 180, 131, 0) 100%);
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.intro-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.intro-content p {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #333;
  font-weight: 300;
}

/* 关键组件部分 */
.key-components-section {
  padding: 100px 0;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.key-components-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 20% 30%, rgba(11, 28, 61, 0.02) 0%, transparent 70%),
    radial-gradient(circle at 80% 70%, rgba(207, 180, 131, 0.02) 0%, transparent 70%);
  z-index: 0;
}

.section-title {
  text-align: center;
  font-size: 2.8rem;
  font-weight: 300;
  color: #0a1c3d;
  margin-bottom: 70px;
  position: relative;
  z-index: 2;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 1px;
  background: #CFB483;
}

/* 新的组件网格布局，采用Flex方式 */
.component-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* 前6个卡片布局为3行2列 */
.component-card {
  flex: 0 0 calc(33.333% - 25px);
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  min-height: 320px;
  max-width: 380px;
}

/* 最后一个卡片特殊处理，更显眼 */
.component-card:last-child {
  flex: 0 0 calc(50% - 25px);
  max-width: 600px;
  margin-top: 15px;
  background: linear-gradient(to right bottom, white, #f9f7f2);
  border-left: 3px solid #CFB483;
}

.card-content {
  padding: 35px 30px;
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.component-card:last-child .card-content {
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

.component-card:last-child .icon-container {
  margin-bottom: 0;
}

.component-card:last-child .text-content {
  flex: 1;
}

.icon-container {
  width: 60px;
  height: 60px;
  min-width: 60px;
  background: linear-gradient(135deg, #0a1c3d, #132952);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  color: #CFB483;
  font-size: 1.5rem;
  transition: all 0.4s ease;
  box-shadow: 0 5px 15px rgba(10, 28, 61, 0.2);
}

.component-card:hover .icon-container {
  transform: scale(1.1) rotate(10deg);
  box-shadow: 0 8px 20px rgba(10, 28, 61, 0.3);
}

.component-card h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 15px;
  color: #0a1c3d;
  position: relative;
  padding-bottom: 15px;
}

.component-card h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 1px;
  background: #CFB483;
  transition: all 0.3s ease;
}

.component-card:hover h3::after {
  width: 60px;
}

.component-card p {
  font-size: 1rem;
  line-height: 1.7;
  color: #555;
  font-weight: 300;
  flex-grow: 1;
}

.card-bg-elements {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.element {
  position: absolute;
  border-radius: 50%;
  opacity: 0.05;
  background: #CFB483;
  transition: all 0.5s ease;
}

.element-1 {
  width: 150px;
  height: 150px;
  top: -75px;
  right: -75px;
}

.element-2 {
  width: 100px;
  height: 100px;
  bottom: -50px;
  left: -50px;
}

.corner-accent {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-top: 3px solid #CFB483;
  border-right: 3px solid #CFB483;
  opacity: 0.3;
  transition: all 0.3s ease;
}

.component-card:hover .element-1 {
  transform: scale(1.3);
}

.component-card:hover .element-2 {
  transform: scale(1.2);
}

.component-card:hover .corner-accent {
  width: 40px;
  height: 40px;
  opacity: 0.5;
}

/* 图片展示部分 */
.showcase-section {
  height: 80vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.showcase-parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 1;
}

.img-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(10, 28, 61, 0.9), rgba(10, 28, 61, 0.7));
}

.showcase-content {
  width: 100%;
  position: relative;
  z-index: 2;
}

.content-wrapper {
  max-width: 600px;
  margin-left: 10%;
  color: white;
  padding: 40px;
  background: rgba(10, 28, 61, 0.3);
  backdrop-filter: blur(10px);
  border-left: 3px solid #CFB483;
}

.showcase-content h2 {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.content-separator {
  width: 80px;
  height: 1px;
  background: #CFB483;
  margin-bottom: 25px;
}

.showcase-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.9);
}

.contact-button {
  background: transparent;
  color: white;
  border: 1px solid #CFB483;
  padding: 15px 35px;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.contact-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #CFB483;
  transition: all 0.3s ease;
  z-index: -1;
}

.contact-button:hover::before {
  width: 100%;
}

.contact-button:hover {
  color: #0a1c3d;
}

/* CTA 部分 */
.cta-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #0c1e42, #132952);
  position: relative;
  overflow: hidden;
}

.cta-section::before {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border: 1px solid rgba(207, 180, 131, 0.1);
  border-radius: 50%;
  top: -150px;
  right: -150px;
}

.cta-section::after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid rgba(207, 180, 131, 0.1);
  bottom: -100px;
  left: -100px;
  transform: rotate(45deg);
}

.cta-content {
  text-align: center;
  color: white;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.cta-content h2 {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.cta-content p {
  font-size: 1.3rem;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.9);
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.primary-btn, .secondary-btn {
  padding: 15px 35px;
  font-size: 1rem;
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.primary-btn {
  background: #CFB483;
  color: #0a1c3d;
  font-weight: 500;
}

.primary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.5s ease;
  z-index: -1;
}

.primary-btn:hover::before {
  left: 100%;
}

.secondary-btn {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-weight: 300;
}

.secondary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  z-index: -1;
}

.secondary-btn:hover::before {
  width: 100%;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .component-card {
    flex: 0 0 calc(50% - 25px);
  }
  
  .component-card:last-child {
    flex: 0 0 calc(50% - 25px);
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .component-card,
  .component-card:last-child {
    flex: 0 0 100%;
    max-width: 450px;
  }
  
  .component-card:last-child .card-content {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .component-card:last-child .icon-container {
    margin-bottom: 25px;
  }
  
  .management-hero-content h1 {
    font-size: 2.5rem;
    letter-spacing: 6px;
  }
  
  .management-hero-content h2 {
    font-size: 1.5rem;
  }
  
  .management-hero-content h3 {
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
  
  .intro-content p {
    font-size: 1.2rem;
  }
  
  .section-title {
    font-size: 2.2rem;
  }
  
  .showcase-section {
    height: auto;
    padding: 80px 0;
  }
  
  .content-wrapper {
    margin: 0 auto;
    max-width: 90%;
  }
  
  .showcase-content h2 {
    font-size: 2rem;
  }
  
  .cta-content h2 {
    font-size: 2rem;
  }
  
  .cta-content p {
    font-size: 1.1rem;
  }
  
  .cta-buttons {
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .management-intro-section,
  .key-components-section {
    padding: 70px 0;
  }
}

@media (max-width: 480px) {
  .management-hero-content h1 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
  
  .management-hero-content h2 {
    font-size: 1.3rem;
  }
  
  .component-card {
    min-height: auto;
  }
} 