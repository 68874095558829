/* AsiaPacificMarkets.css */
/* 全局样式设置 */
.asia-pacific {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  overflow-x: hidden;
  --gold-primary: #b29366;
  --gold-light: rgba(178, 147, 102, 0.15);
  --gold-medium: rgba(178, 147, 102, 0.5);
  --dark-blue: #0a192f;
  --medium-blue: #172a46;
  --light-blue: #1e355a;
  --text-dark: #2c3e50;
  --text-light: #f8f9fa;
  --text-muted: #7f8c8d;
  --section-padding: 90px 8%;
  --accent-gold: rgba(178, 147, 102, 0.8);
  --accent-gold-light: rgba(178, 147, 102, 0.1);
}

/* 英雄区域样式 */
.asia-pacific .hero-section {
  position: relative;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--dark-blue);
}

.asia-pacific .background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.asia-pacific .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, 
    rgba(10, 25, 47, 0.9) 0%, 
    rgba(23, 42, 70, 0.85) 50%, 
    rgba(10, 25, 47, 0.9) 100%);
  z-index: 1;
}

/* 新增东亚装饰图案覆盖层 */
.asia-pacific .pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23b29366' fill-opacity='0.6'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  z-index: 2;
}

/* 日本风地图线条装饰 */
.asia-pacific .map-decoration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.06;
  background-size: cover;
  background-position: center;
  mix-blend-mode: luminosity;
}

/* 动画形状 */
.asia-pacific .animated-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.asia-pacific .shape {
  position: absolute;
  backdrop-filter: blur(3px);
  transform-origin: center;
  animation: float 15s infinite ease-in-out;
}

.asia-pacific .shape-1 {
  width: 40vmin;
  height: 40vmin;
  top: -10vmin;
  left: -5vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 35% 65% 65% 35% / 35% 35% 65% 65%;
  background: linear-gradient(145deg, rgba(10, 25, 47, 0.1), rgba(178, 147, 102, 0.05));
  box-shadow: 0 0 60px rgba(178, 147, 102, 0.05);
  animation-duration: 25s;
}

.asia-pacific .shape-2 {
  width: 35vmin;
  height: 35vmin;
  bottom: -10vmin;
  right: -8vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 65% 35% 35% 65% / 35% 65% 35% 65%;
  background: linear-gradient(145deg, rgba(23, 42, 70, 0.1), rgba(10, 25, 47, 0.1));
  box-shadow: 0 0 40px rgba(178, 147, 102, 0.05);
  animation-delay: -5s;
  animation-duration: 20s;
}

.asia-pacific .shape-3 {
  display: block;
  width: 25vmin;
  height: 25vmin;
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(178, 147, 102, 0.08);
  border-radius: 40% 60% 50% 50% / 40% 40% 60% 60%;
  background: linear-gradient(145deg, rgba(178, 147, 102, 0.05), rgba(10, 25, 47, 0.05));
  opacity: 0.3;
  animation-delay: -10s;
  animation-duration: 18s;
}

/* 创新: 金色水墨风格装饰元素 */
.asia-pacific .ink-decoration {
  position: absolute;
  width: 60vmin;
  height: 60vmin;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  background: radial-gradient(ellipse at center, rgba(178, 147, 102, 0.01) 0%, transparent 70%);
  filter: blur(20px);
  opacity: 0.3;
  z-index: 1;
  animation: pulse 15s infinite alternate;
}

/* 动画效果 */
@keyframes float {
  0% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
  25% {
    transform: rotate(3deg) translate(10px, 10px) scale(1.01);
  }
  50% {
    transform: rotate(0deg) translate(20px, 5px) scale(1);
  }
  75% {
    transform: rotate(-3deg) translate(5px, 15px) scale(0.99);
  }
  100% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.2;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.3;
    transform: translate(-50%, -50%) scale(1.1);
  }
}

/* 光效设计 */
.asia-pacific .light-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(178, 147, 102, 0.05) 0%, transparent 35%),
    radial-gradient(circle at 80% 80%, rgba(178, 147, 102, 0.06) 0%, transparent 35%),
    radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.02) 0%, transparent 50%);
  z-index: 2;
  opacity: 1;
}

.asia-pacific .line-decoration {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.4;
}

.asia-pacific .line-decoration::before,
.asia-pacific .line-decoration::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, transparent 0%, var(--gold-primary) 50%, transparent 100%);
  height: 1px;
}

.asia-pacific .line-decoration::before {
  top: 25%;
  left: 0;
  width: 35%;
  transform: rotate(-2deg);
  filter: blur(0.5px);
}

.asia-pacific .line-decoration::after {
  bottom: 30%;
  right: 0;
  width: 35%;
  transform: rotate(2deg);
  filter: blur(0.5px);
}

.asia-pacific .hero-content {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.asia-pacific .hero-content h1 {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 6px;
  color: var(--gold-primary);
  margin-bottom: 25px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 15px;
  display: inline-block;
}

.asia-pacific .hero-content h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 1px;
  background: var(--gold-primary);
}

.asia-pacific .hero-content h2 {
  font-size: 3.3rem;
  font-weight: 300;
  color: var(--text-light);
  margin-bottom: 30px;
  letter-spacing: 0;
  line-height: 1.2;
  background: linear-gradient(120deg, #ffffff, #e8e8e8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: 100%;
}

.asia-pacific .hero-content h3 {
  font-size: 1.4rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 40px;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.asia-pacific .hero-content h3::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, var(--gold-primary), transparent);
}

.asia-pacific .hero-button-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.asia-pacific .cta-button {
  background-color: transparent;
  color: var(--text-light);
  border: 1px solid var(--gold-primary);
  padding: 15px 32px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-top: 10px;
  display: inline-block;
}

.asia-pacific .cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.asia-pacific .cta-button:hover {
  color: var(--text-light);
}

.asia-pacific .cta-button:hover::before {
  width: 100%;
}

/* 介绍部分样式 */
.asia-pacific .introduction {
  padding: var(--section-padding);
  background-color: #fafafa;
  position: relative;
  overflow: hidden;
}

.asia-pacific .intro-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
}

.asia-pacific .intro-bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(var(--gold-light) 1px, transparent 1px),
    radial-gradient(var(--gold-light) 1px, transparent 1px);
  background-size: 40px 40px, 30px 30px;
  background-position: 0 0, 20px 20px;
  opacity: 0.2;
  z-index: 1;
}

.asia-pacific .intro-content {
  flex: 1;
  min-width: 300px;
  padding-right: 40px;
  position: relative;
}

.asia-pacific .intro-image {
  flex: 0.8;
  min-width: 300px;
  position: relative;
  margin-top: 40px;
}

.asia-pacific .intro-image-frame {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.asia-pacific .intro-image-frame img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;
}

.asia-pacific .intro-image-frame:hover img {
  transform: scale(1.05);
}

.asia-pacific .intro-image::before {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--gold-primary);
  z-index: -1;
  opacity: 0.4;
}

.asia-pacific .intro-image::after {
  content: '';
  position: absolute;
  bottom: -15px;
  right: -15px;
  width: 40%;
  height: 40%;
  background: var(--gold-light);
  z-index: -1;
  opacity: 0.5;
}

.asia-pacific .section-title {
  font-size: 2.2rem;
  font-weight: 300;
  color: var(--dark-blue);
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;
  line-height: 1.3;
}

.asia-pacific .section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, var(--medium-blue), var(--gold-primary));
}

.asia-pacific .section-text {
  font-size: 1.05rem;
  line-height: 1.8;
  color: var(--text-muted);
  margin-bottom: 25px;
}

/* 专业优势部分 */
.asia-pacific .expertise {
  padding: var(--section-padding);
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.asia-pacific .expertise-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(135deg, var(--gold-light) 0%, transparent 100%),
    linear-gradient(45deg, transparent 0%, var(--gold-light) 100%);
  opacity: 0.1;
  z-index: 1;
}

.asia-pacific .expertise-container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.asia-pacific .expertise-content {
  flex: 1;
  min-width: 300px;
}

.asia-pacific .expertise-image {
  flex: 1;
  min-width: 300px;
  position: relative;
}

.asia-pacific .expertise-grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 200px);
  gap: 20px;
  margin-top: 30px;
}

.asia-pacific .expertise-item {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.asia-pacific .expertise-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;
}

.asia-pacific .expertise-item:hover img {
  transform: scale(1.05);
}

.asia-pacific .expertise-item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: linear-gradient(to top, rgba(10, 25, 47, 0.8), transparent);
  color: white;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.asia-pacific .expertise-item:hover .expertise-item-overlay {
  transform: translateY(0);
}

.asia-pacific .expertise-item-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.asia-pacific .expertise-item-text {
  font-size: 0.85rem;
  opacity: 0.9;
}

/* 特色与优势部分 */
.asia-pacific .features {
  padding: var(--section-padding);
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.asia-pacific .features-title {
  text-align: center;
  margin-bottom: 60px;
}

.asia-pacific .features-title .section-title {
  display: inline-block;
}

.asia-pacific .features-title .section-title::after {
  left: 50%;
  transform: translateX(-50%);
}

.asia-pacific .features-container {
  position: relative;
  z-index: 2;
  margin-top: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.asia-pacific .features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.asia-pacific .feature-item {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  padding: 30px;
  border-top: 3px solid transparent;
  border-image: linear-gradient(to right, var(--gold-primary), var(--medium-blue));
  border-image-slice: 1;
}

.asia-pacific .feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.asia-pacific .feature-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--gold-light), var(--medium-blue));
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  transition: all 0.3s ease;
  color: var(--dark-blue);
  font-size: 1.5rem;
}

.asia-pacific .feature-icon i {
  font-size: 1.8rem;
  color: var(--dark-blue);
  transition: all 0.3s ease;
}

.asia-pacific .feature-item:hover .feature-icon {
  background: linear-gradient(135deg, var(--gold-primary), var(--medium-blue));
  color: white;
}

.asia-pacific .feature-item:hover .feature-icon i {
  color: white;
}

.asia-pacific .feature-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--dark-blue);
  margin-bottom: 15px;
}

.asia-pacific .feature-text {
  font-size: 0.95rem;
  color: var(--text-muted);
  line-height: 1.7;
}

/* 全球资本部分 */
.asia-pacific .global-capital {
  padding: var(--section-padding);
  background-color: var(--dark-blue);
  color: white;
  position: relative;
  overflow: hidden;
}

.asia-pacific .global-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-size: cover;
  background-position: center;
  filter: grayscale(60%);
}

.asia-pacific .global-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, 
    rgba(10, 25, 47, 0.95) 0%, 
    rgba(23, 42, 70, 0.9) 100%);
  z-index: 1;
}

.asia-pacific .global-container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.asia-pacific .global-content {
  flex: 1;
  min-width: 300px;
}

.asia-pacific .global-content .section-title {
  color: white;
}

.asia-pacific .global-content .section-title::after {
  background: linear-gradient(90deg, var(--gold-primary), var(--medium-blue));
}

.asia-pacific .global-content .section-text {
  color: rgba(255, 255, 255, 0.8);
}

.asia-pacific .global-image {
  flex: 0.8;
  min-width: 300px;
  position: relative;
}

.asia-pacific .global-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.asia-pacific .stat-item {
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.asia-pacific .stat-item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-5px);
}

.asia-pacific .stat-number {
  font-size: 2.5rem;
  font-weight: 300;
  color: var(--gold-primary);
  margin-bottom: 10px;
  line-height: 1;
}

.asia-pacific .stat-text {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
}

/* 联系部分样式 */
.asia-pacific .contact {
  padding: var(--section-padding);
  background-color: #fafafa;
  position: relative;
  overflow: hidden;
}

.asia-pacific .contact-container {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.asia-pacific .contact-container .section-title {
  text-align: center;
  display: inline-block;
}

.asia-pacific .contact-container .section-title::after {
  left: 50%;
  transform: translateX(-50%);
}

.asia-pacific .contact-text {
  font-size: 1.1rem;
  line-height: 1.7;
  color: var(--text-muted);
  margin-bottom: 40px;
}

.asia-pacific .contact-button {
  background-color: transparent;
  color: var(--dark-blue);
  border: 1px solid var(--gold-primary);
  padding: 15px 40px;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  text-decoration: none;
}

.asia-pacific .contact-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.asia-pacific .contact-button:hover {
  color: white;
}

.asia-pacific .contact-button:hover::before {
  width: 100%;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .asia-pacific .hero-content h2 {
    font-size: 3rem;
  }
  
  .asia-pacific .section-title {
    font-size: 2rem;
  }
  
  .asia-pacific .expertise-grid {
    grid-template-rows: repeat(2, 180px);
  }
}

@media (max-width: 992px) {
  .asia-pacific .hero-content h1 {
    font-size: 1rem;
  }
  
  .asia-pacific .hero-content h2 {
    font-size: 2.5rem;
  }
  
  .asia-pacific .hero-content h3 {
    font-size: 1.2rem;
  }
  
  .asia-pacific .section-title {
    font-size: 1.8rem;
  }
  
  .asia-pacific .intro-content {
    padding-right: 0;
    margin-bottom: 40px;
  }
  
  .asia-pacific .expertise-container {
    flex-direction: column;
  }
  
  .asia-pacific .expertise-content {
    order: 1;
  }
  
  .asia-pacific .expertise-image {
    order: 2;
  }
}

@media (max-width: 768px) {
  .asia-pacific .hero-section {
    height: 80vh;
  }
  
  .asia-pacific .hero-content h1 {
    font-size: 0.9rem;
    letter-spacing: 4px;
  }
  
  .asia-pacific .hero-content h2 {
    font-size: 2.2rem;
  }
  
  .asia-pacific .hero-content h3 {
    font-size: 1.1rem;
  }
  
  .asia-pacific .section-title {
    font-size: 1.6rem;
  }
  
  .asia-pacific .section-text {
    font-size: 1rem;
  }
  
  .asia-pacific .expertise-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 200px);
  }
  
  .asia-pacific .stat-number {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .asia-pacific .hero-content h1 {
    font-size: 0.8rem;
    letter-spacing: 3px;
  }
  
  .asia-pacific .hero-content h2 {
    font-size: 1.8rem;
  }
  
  .asia-pacific .hero-content h3 {
    font-size: 1rem;
  }
  
  .asia-pacific .cta-button {
    padding: 12px 25px;
    font-size: 0.8rem;
  }
  
  .asia-pacific .section-title {
    font-size: 1.5rem;
  }
  
  .asia-pacific .intro-image::before,
  .asia-pacific .intro-image::after {
    display: none;
  }
  
  .asia-pacific .contact-text {
    font-size: 1rem;
  }
  
  .asia-pacific .contact-button {
    padding: 12px 30px;
    font-size: 0.9rem;
  }
} 