/* 住宅项目营销页面高级样式 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

.project-marketing {
  width: 100%;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  color: #222;
  background-color: #fbfbfb;
}

/* 英雄部分 - 全新高级设计背景 */
.project-hero-section {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Canvas背景 */
.background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* 金色奢华装饰元素 */
.prestige-decoration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}

.golden-separator-top,
.golden-separator-bottom {
  position: absolute;
  left: 5%;
  width: 90%;
  height: 1px;
  background: linear-gradient(90deg, 
    rgba(207, 180, 131, 0) 0%, 
    rgba(207, 180, 131, 0.3) 15%, 
    rgba(207, 180, 131, 0.7) 50%, 
    rgba(207, 180, 131, 0.3) 85%, 
    rgba(207, 180, 131, 0) 100%);
}

.golden-separator-top {
  top: 15%;
  animation: fadeInWidth 2s ease-in-out forwards;
}

.golden-separator-bottom {
  bottom: 15%;
  animation: fadeInWidth 2s ease-in-out 0.5s forwards;
}

/* 高级建筑轮廓装饰 */
.golden-building-silhouette {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 35%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 300" fill="none" stroke="rgba(207, 180, 131, 0.1)" stroke-width="1"><path d="M0,300 L0,220 L100,220 L100,150 L200,150 L200,100 L300,100 L300,150 L400,150 L400,80 L500,80 L500,130 L600,130 L600,50 L700,50 L700,180 L800,180 L800,100 L900,100 L900,220 L1000,220 L1000,170 L1100,170 L1100,220 L1200,220 L1200,300 Z" /><path d="M600,50 L600,0" /><path d="M520,80 L580,20" /><path d="M680,50 L740,110" /><path d="M700,90 L760,30" /><path d="M400,80 L460,20" /><path d="M830,100 L890,40" /></svg>');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  opacity: 0;
  animation: fadeIn 3s ease-in-out 1.5s forwards;
}

/* 浮动元素 */
.hero-floating-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;
}

.floating-element {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 70%);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(207, 180, 131, 0.1);
}

.element-1 {
  width: 300px;
  height: 300px;
  top: 10%;
  right: 10%;
  animation: float1 20s ease-in-out infinite alternate;
  opacity: 0;
  animation-delay: 0.5s;
}

.element-2 {
  width: 200px;
  height: 200px;
  bottom: 20%;
  left: 5%;
  animation: float2 15s ease-in-out infinite alternate;
  opacity: 0;
  animation-delay: 1s;
}

.element-3 {
  width: 150px;
  height: 150px;
  top: 30%;
  left: 15%;
  animation: float3 10s ease-in-out infinite alternate;
  opacity: 0;
  animation-delay: 1.5s;
}

.golden-accent {
  position: absolute;
  background-color: rgba(207, 180, 131, 0.02);
  border: 1px solid rgba(207, 180, 131, 0.1);
  transform: rotate(45deg);
}

.accent-1 {
  width: 100px;
  height: 100px;
  top: 25%;
  right: 25%;
  animation: rotate1 30s linear infinite;
}

.accent-2 {
  width: 150px;
  height: 150px;
  bottom: 30%;
  right: 10%;
  animation: rotate2 40s linear infinite;
}

/* 钻石标志 */
.prestige-logo {
  margin-bottom: 30px;
  position: relative;
}

.logo-diamond {
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 2px solid #CFB483;
  transform: rotate(45deg);
  margin: 0 auto;
  position: relative;
  animation: pulsate 3s ease-in-out infinite;
}

.logo-diamond::before,
.logo-diamond::after {
  content: '';
  position: absolute;
  background-color: rgba(207, 180, 131, 0.2);
}

.logo-diamond::before {
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  transform: rotate(45deg);
}

.logo-diamond::after {
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  transform: rotate(45deg);
}

/* 英雄内容样式 */
.project-hero-content {
  position: relative;
  text-align: center;
  z-index: 10;
  color: white;
  max-width: 800px;
  padding: 0 20px;
}

.project-hero-content h1 {
  font-size: 3.5rem;
  font-weight: 300;
  letter-spacing: 8px;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  color: #fff;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.project-hero-content h1::after {
  content: '';
  position: absolute;
  width: 60px;
  height: 1px;
  background-color: #CFB483;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.project-hero-content h2 {
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 20px;
  letter-spacing: 3px;
  color: #CFB483;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.project-hero-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 40px;
  font-weight: 300;
  letter-spacing: 1px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
}

.project-cta-button {
  background: transparent;
  color: #fff;
  border: 1px solid #CFB483;
  padding: 15px 40px;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-transform: uppercase;
}

.project-cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #CFB483;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  z-index: -1;
}

.project-cta-button:hover::before {
  width: 100%;
}

.project-cta-button:hover {
  color: #0a1c3d;
}

/* 动画定义 */
@keyframes fadeInWidth {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 90%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulsate {
  0% {
    transform: rotate(45deg) scale(1);
    box-shadow: 0 0 10px rgba(207, 180, 131, 0.2);
  }
  50% {
    transform: rotate(45deg) scale(1.05);
    box-shadow: 0 0 20px rgba(207, 180, 131, 0.4);
  }
  100% {
    transform: rotate(45deg) scale(1);
    box-shadow: 0 0 10px rgba(207, 180, 131, 0.2);
  }
}

@keyframes float1 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 0.3;
  }
  100% {
    transform: translate(30px, 20px);
    opacity: 0.3;
  }
}

@keyframes float2 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  100% {
    transform: translate(-20px, -30px);
    opacity: 0.2;
  }
}

@keyframes float3 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 0.15;
  }
  100% {
    transform: translate(15px, -15px);
    opacity: 0.15;
  }
}

@keyframes rotate1 {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

/* 介绍部分 */
.project-introduction {
  padding: 100px 0;
  background-color: #fbfbfb;
  position: relative;
}

.project-introduction::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(45deg, rgba(11, 28, 61, 0.02) 0%, rgba(11, 28, 61, 0) 70%),
    linear-gradient(135deg, rgba(207, 180, 131, 0.02) 0%, rgba(207, 180, 131, 0) 80%);
}

.intro-content {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
}

.project-introduction h2 {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 25px;
  letter-spacing: 2px;
  color: #0a1c3d;
}

.gold-separator {
  width: 80px;
  height: 1px;
  background-color: #CFB483;
  margin: 0 auto 30px;
}

.project-introduction p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
  font-weight: 300;
}

/* 引言部分 */
.project-quote-section {
  position: relative;
  height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.quote-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.quote-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(10, 28, 61, 0.92), rgba(10, 28, 61, 0.8));
  z-index: 2;
}

.quote-content {
  position: relative;
  z-index: 3;
  max-width: 800px;
  padding: 0 20px;
  text-align: center;
  color: #fff;
}

.quote-icon {
  font-size: 3rem;
  color: #CFB483;
  opacity: 0.8;
  margin-bottom: 20px;
}

.quote-content h3 {
  font-size: 2.2rem;
  font-weight: 300;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: #CFB483;
}

.quote-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 25px;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.quote-signature {
  font-style: italic;
  font-size: 1.1rem;
  color: #CFB483;
}

.quote-signature:before {
  content: "— ";
}

/* 服务网格部分 */
.project-services-container {
  padding: 100px 0;
  background-color: #fbfbfb;
  position: relative;
}

.project-services-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(45deg, rgba(11, 28, 61, 0.01) 0%, rgba(11, 28, 61, 0) 70%),
    linear-gradient(135deg, rgba(207, 180, 131, 0.01) 0%, rgba(207, 180, 131, 0) 80%);
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 60px;
  letter-spacing: 2px;
  color: #0a1c3d;
  position: relative;
  display: inline-block;
  width: 100%;
}

.section-title::after {
  content: '';
  position: absolute;
  width: 60px;
  height: 1px;
  background-color: #CFB483;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.section-title::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #CFB483;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.project-services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.project-service-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.project-service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 0;
  background-color: #CFB483;
  transition: all 0.4s ease;
}

.project-service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.project-service-card:hover::before {
  height: 100%;
}

.service-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
  transition: all 0.4s ease;
}

.project-service-card:hover .service-image-container {
  height: 220px;
}

.service-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.project-service-card:hover .service-image-container img {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(10, 28, 61, 0.2),
    rgba(10, 28, 61, 0.5)
  );
  opacity: 0.6;
  transition: all 0.4s ease;
}

.project-service-card:hover .image-overlay {
  opacity: 0.3;
}

.service-number {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: rgba(207, 180, 131, 0.9);
  padding: 4px 10px;
  border-radius: 4px;
  z-index: 2;
  transition: all 0.3s ease;
}

.project-service-card:hover .service-number {
  background-color: #0a1c3d;
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.service-content {
  padding: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.service-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.service-icon {
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a1c3d;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.project-service-card:hover .service-icon {
  background-color: #CFB483;
  color: #fff;
  transform: rotate(360deg);
}

.project-service-card h3 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 5px;
  color: #0a1c3d;
  transition: all 0.3s ease;
  letter-spacing: 1px;
}

.project-service-card:hover h3 {
  color: #CFB483;
}

.service-separator {
  width: 40px;
  height: 1px;
  background-color: #CFB483;
  margin: 10px 0 15px;
  transition: all 0.4s ease;
}

.project-service-card:hover .service-separator {
  width: 60px;
  background-color: #0a1c3d;
}

.project-service-card p {
  font-size: 0.95rem;
  line-height: 1.7;
  color: #666;
  margin-bottom: 20px;
  flex-grow: 1;
}

.project-service-card:hover p {
  color: #333;
}

.service-read-more {
  font-size: 0.95rem;
  font-weight: 500;
  color: #0a1c3d;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
}

.service-read-more i {
  margin-left: 8px;
  transition: all 0.3s ease;
}

.project-service-card:hover .service-read-more {
  color: #CFB483;
}

.project-service-card:hover .service-read-more i {
  transform: translateX(5px);
}

/* 联系部分 */
.project-contact-section {
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.contact-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.contact-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(10, 28, 61, 0.92), rgba(10, 28, 61, 0.8));
  z-index: 2;
}

.project-contact-content {
  position: relative;
  z-index: 3;
  max-width: 800px;
  padding: 0 20px;
  text-align: center;
  color: #fff;
}

.project-contact-content h2 {
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.contact-separator {
  width: 60px;
  height: 1px;
  background-color: #CFB483;
  margin: 0 auto 25px;
}

.project-contact-content p {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 30px;
  font-weight: 300;
}

.project-cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.project-primary-btn, .project-secondary-btn {
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 4px;
}

.project-primary-btn {
  background-color: #CFB483;
  color: #fff;
}

.project-primary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.6s ease;
  z-index: -1;
}

.project-primary-btn:hover::before {
  left: 100%;
}

.project-primary-btn:hover {
  background-color: #b69c6c;
}

.project-secondary-btn {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.project-secondary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  z-index: -1;
}

.project-secondary-btn:hover::before {
  width: 100%;
}

.project-secondary-btn:hover {
  color: #0a1c3d;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .project-services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .project-service-card h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .project-services-container {
    padding: 80px 0;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .project-services-grid {
    grid-template-columns: 1fr;
  }
  
  .service-image-container {
    height: 180px;
  }
  
  .project-hero-section {
    height: 100vh;
  }
  
  .project-hero-content h1 {
    font-size: 2.8rem;
    letter-spacing: 4px;
  }
  
  .project-hero-content h2 {
    font-size: 1.5rem;
  }
  
  .golden-building-silhouette {
    height: 25%;
  }
  
  .project-introduction, .project-services-container {
    padding: 70px 0;
  }
  
  .section-title, .project-introduction h2 {
    font-size: 2rem;
    margin-bottom: 40px;
  }
  
  .project-cta-buttons {
    flex-direction: column;
    gap: 15px;
  }
  
  .project-primary-btn, .project-secondary-btn {
    width: 100%;
  }
  
  .project-contact-content h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .project-hero-content h1 {
    font-size: 2.2rem;
    letter-spacing: 3px;
  }
  
  .project-hero-content h2 {
    font-size: 1.2rem;
  }
  
  .project-introduction p, .project-hero-content p, .project-contact-content p {
    font-size: 1rem;
  }
  
  .logo-diamond {
    width: 50px;
    height: 50px;
  }
} 