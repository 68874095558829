/* 新的豪华地产销售页面样式 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

.rs-luxury-residential-sell {
  width: 100%;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  color: #222;
  background-color: #fbfbfb;
}

/* 豪华英雄部分 - 高级设计背景 */
.rs-luxury-hero-section {
  height: 85vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* 设计性背景 */
.rs-designer-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

/* 渐变背景叠加层 */
.rs-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(145deg, 
    #030e24 0%, 
    #0a1c3d 35%, 
    #0e2042 45%, 
    #071630 65%, 
    #030c1d 100%
  );
  z-index: 1;
}

/* 光线效果 */
.rs-pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(ellipse at 15% 15%, rgba(207, 180, 131, 0.08) 0%, transparent 60%),
    radial-gradient(ellipse at 85% 85%, rgba(207, 180, 131, 0.08) 0%, transparent 60%),
    linear-gradient(135deg, 
      rgba(207, 180, 131, 0.02) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(207, 180, 131, 0.02) 50%, 
      rgba(207, 180, 131, 0.02) 75%, 
      transparent 75%, 
      transparent
    );
  background-size: 100% 100%, 100% 100%, 60px 60px;
  opacity: 1;
  z-index: 2;
}

/* 几何图形装饰 */
.rs-animated-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

/* 奢华金线框架 */
.rs-shape {
  position: absolute;
  border-radius: 0;
  opacity: 0.15;
  border: 1px solid #CFB483;
  box-shadow: 0 0 30px rgba(207, 180, 131, 0.1);
  backdrop-filter: blur(3px);
  transform-origin: center;
}

.rs-shape-1 {
  width: 35vw;
  height: 35vw;
  top: -10%;
  right: -5%;
  border-radius: 44% 56% 70% 30% / 30% 40% 60% 70%;
  border-width: 1px;
  background: radial-gradient(circle, rgba(207, 180, 131, 0.03) 0%, rgba(207, 180, 131, 0) 70%);
  animation: morphShape1 25s ease-in-out infinite alternate;
  transform: rotate(25deg);
}

.rs-shape-2 {
  width: 30vw;
  height: 30vw;
  bottom: -5%;
  left: -5%;
  border-radius: 73% 27% 59% 41% / 57% 43% 57% 43%;
  background: radial-gradient(circle, rgba(11, 28, 61, 0.5) 0%, rgba(11, 28, 61, 0) 70%);
  animation: morphShape2 20s ease-in-out infinite alternate;
  transform: rotate(-15deg);
}

.rs-shape-3 {
  width: 15vw;
  height: 15vw;
  top: 40%;
  left: 20%;
  border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
  background: radial-gradient(circle, rgba(207, 180, 131, 0.02) 0%, rgba(207, 180, 131, 0) 70%);
  animation: morphShape3 15s ease-in-out infinite alternate;
  transform: rotate(45deg);
}

/* 添加金色装饰线条 */
.rs-designer-background::before,
.rs-designer-background::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, rgba(207, 180, 131, 0) 0%, rgba(207, 180, 131, 0.3) 50%, rgba(207, 180, 131, 0) 100%);
  height: 1px;
  width: 80%;
  left: 10%;
  z-index: 4;
}

.rs-designer-background::before {
  top: 15%;
  animation: fadeLineX 8s ease-in-out infinite alternate;
}

.rs-designer-background::after {
  bottom: 15%;
  animation: fadeLineX 8s ease-in-out 1s infinite alternate;
}

/* 垂直线条 */
.rs-gradient-overlay::before,
.rs-gradient-overlay::after {
  content: '';
  position: absolute;
  background: linear-gradient(180deg, rgba(207, 180, 131, 0) 0%, rgba(207, 180, 131, 0.2) 50%, rgba(207, 180, 131, 0) 100%);
  width: 1px;
  height: 70%;
  top: 15%;
  z-index: 4;
}

.rs-gradient-overlay::before {
  left: 25%;
  animation: fadeLineY 10s ease-in-out infinite alternate;
}

.rs-gradient-overlay::after {
  right: 25%;
  animation: fadeLineY 10s ease-in-out 1.5s infinite alternate;
}

/* 3D光点效果 */
.rs-pattern-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(1px 1px at 20% 30%, rgba(255, 255, 255, 0.15) 0%, transparent 100%),
    radial-gradient(1px 1px at 40% 70%, rgba(255, 255, 255, 0.1) 0%, transparent 100%),
    radial-gradient(1px 1px at 60% 40%, rgba(255, 255, 255, 0.1) 0%, transparent 100%),
    radial-gradient(1px 1px at 80% 10%, rgba(255, 255, 255, 0.12) 0%, transparent 100%),
    radial-gradient(1px 1px at 85% 60%, rgba(255, 255, 255, 0.08) 0%, transparent 100%),
    radial-gradient(1px 1px at 10% 60%, rgba(255, 255, 255, 0.08) 0%, transparent 100%),
    radial-gradient(1px 1px at 30% 90%, rgba(255, 255, 255, 0.1) 0%, transparent 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 2;
  animation: twinkling 10s ease-in-out infinite alternate;
}

/* 变形动画 */
@keyframes morphShape1 {
  0% {
    border-radius: 44% 56% 70% 30% / 30% 40% 60% 70%;
    transform: rotate(25deg) translateY(0);
  }
  50% {
    border-radius: 60% 40% 30% 70% / 50% 60% 40% 50%;
  }
  100% {
    border-radius: 30% 70% 50% 50% / 40% 30% 70% 60%;
    transform: rotate(15deg) translateY(40px);
  }
}

@keyframes morphShape2 {
  0% {
    border-radius: 73% 27% 59% 41% / 57% 43% 57% 43%;
    transform: rotate(-15deg) translateY(0);
  }
  50% {
    border-radius: 40% 60% 30% 70% / 40% 60% 40% 60%;
  }
  100% {
    border-radius: 60% 40% 40% 60% / 30% 70% 70% 30%;
    transform: rotate(-25deg) translateY(-40px);
  }
}

@keyframes morphShape3 {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
    transform: rotate(45deg) translate(0, 0);
  }
  50% {
    border-radius: 40% 60% 50% 50% / 60% 40% 60% 40%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 40% 60% 40% 60%;
    transform: rotate(30deg) translate(30px, 30px);
  }
}

@keyframes fadeLineX {
  0% {
    opacity: 0;
    transform: scaleX(0.6);
  }
  50% {
    opacity: 1;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(0.6);
  }
}

@keyframes fadeLineY {
  0% {
    opacity: 0;
    transform: scaleY(0.6);
  }
  50% {
    opacity: 0.7;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(0.6);
  }
}

@keyframes twinkling {
  0% {
    opacity: 0.3;
    transform: translateZ(0);
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
    transform: translateZ(0);
  }
}

/* 增强光泽感的特殊效果 */
@supports (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)) {
  .rs-shape-1, .rs-shape-2, .rs-shape-3 {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}

.rs-luxury-hero-content {
  position: relative;
  text-align: center;
  color: white;
  padding: 3rem;
  max-width: 900px;
  z-index: 10;
}

.rs-luxury-branding {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.rs-gold-line {
  height: 1px;
  width: 60px;
  background: linear-gradient(90deg, rgba(207, 180, 131, 0.1), rgba(207, 180, 131, 1), rgba(207, 180, 131, 0.1));
  margin: 0 1.5rem;
}

.rs-luxury-hero-content h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  font-weight: 200;
  letter-spacing: 8px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  background: linear-gradient(45deg, #fff, #CFB483);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
}

.rs-luxury-hero-content h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 1px;
  background: linear-gradient(90deg, rgba(207, 180, 131, 0.1), rgba(207, 180, 131, 1), rgba(207, 180, 131, 0.1));
}

.rs-luxury-hero-content h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  color: rgba(255, 255, 255, 0.9);
}

.rs-luxury-hero-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 2.5rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.rs-luxury-cta-button {
  background: transparent;
  border: 1px solid rgba(207, 180, 131, 0.6);
  color: white;
  padding: 0.9rem 2.2rem;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.rs-luxury-cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(207, 180, 131, 0.8);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: -1;
}

.rs-luxury-cta-button:hover::before {
  width: 100%;
}

.rs-luxury-cta-button:hover {
  color: #111;
  border-color: rgba(207, 180, 131, 0.8);
}

/* 豪华介绍部分 */
.rs-luxury-introduction {
  padding: 8rem 2rem;
  background-color: #fff;
  color: #0b1c3d;
  position: relative;
}

.rs-luxury-introduction::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(207, 180, 131, 0.3), transparent);
}

.rs-intro-content {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

.rs-luxury-introduction h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #0b1c3d;
}

.rs-gold-separator {
  height: 2px;
  width: 120px;
  background: linear-gradient(90deg, rgba(207, 180, 131, 0.1), rgba(207, 180, 131, 1), rgba(207, 180, 131, 0.1));
  margin: 0 auto 2.5rem;
}

.rs-luxury-introduction p {
  font-size: 1.1rem;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #333;
}

/* 豪华服务容器 - 重新设计 */
.rs-luxury-services-container {
  padding: 10rem 4rem;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, #f9f9f9, #fff);
}

.rs-luxury-services-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(207, 180, 131, 0.3), transparent);
}

.rs-section-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 8rem;
  font-weight: 300;
  color: #0b1c3d;
  position: relative;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.rs-section-title::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 1px;
  background: #CFB483;
}

.rs-section-title::before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 1px;
  background: rgba(207, 180, 131, 0.3);
}

/* 新的高级服务网格设计 */
.rs-luxury-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 4rem;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.rs-luxury-service-card {
  background: white;
  border-radius: 0;
  overflow: visible;
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
  box-shadow: none;
  border: 1px solid rgba(207, 180, 131, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rs-luxury-service-card::before {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(45deg, rgba(207, 180, 131, 0), rgba(207, 180, 131, 0.05), rgba(207, 180, 131, 0.1), rgba(207, 180, 131, 0.05), rgba(207, 180, 131, 0));
  z-index: -1;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.rs-luxury-service-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.05);
  border-color: rgba(207, 180, 131, 0.1);
}

.rs-luxury-service-card:hover::before {
  opacity: 1;
}

.rs-service-image-container {
  position: relative;
  height: 300px;
  overflow: hidden;
  margin: 0;
  clip-path: inset(0);
  transition: clip-path 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}

.rs-luxury-service-card:hover .rs-service-image-container {
  clip-path: inset(0 0 0 0);
}

.rs-service-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  filter: saturate(0.9) brightness(0.95);
}

.rs-luxury-service-card:hover .rs-service-image-container img {
  transform: scale(1.08);
  filter: saturate(1) brightness(1);
}

.rs-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,0.4) 100%);
  opacity: 0.7;
  transition: opacity 0.7s ease;
}

.rs-luxury-service-card:hover .rs-image-overlay {
  opacity: 0.5;
}

.rs-service-number {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 3rem;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.5;
  z-index: 2;
  transition: all 0.5s ease;
}

.rs-luxury-service-card:hover .rs-service-number {
  color: #CFB483;
  opacity: 0.9;
  transform: scale(1.1);
}

.rs-service-content {
  padding: 2.5rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
}

.rs-service-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.rs-service-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(11, 28, 61, 0.05);
  color: #0b1c3d;
  border-radius: 50%;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  transition: all 0.5s ease;
}

.rs-luxury-service-card:hover .rs-service-icon {
  background: #0b1c3d;
  color: #fff;
  transform: rotateY(180deg);
}

.rs-luxury-service-card h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #0b1c3d;
  position: relative;
  transition: all 0.5s ease;
}

.rs-luxury-service-card h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1.5rem;
  color: #CFB483;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.rs-luxury-service-card:hover h3 {
  transform: translateX(5px);
}

.rs-luxury-service-card:hover h4 {
  transform: translateX(5px);
  color: #0b1c3d;
}

.rs-service-separator {
  height: 1px;
  width: 50px;
  background: rgba(207, 180, 131, 0.5);
  margin-bottom: 1.5rem;
  transition: all 0.5s ease;
}

.rs-luxury-service-card:hover .rs-service-separator {
  width: 80px;
  background: #CFB483;
}

.rs-luxury-service-card p {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
  margin: 0;
  margin-bottom: auto;
  transition: all 0.5s ease;
}

.rs-luxury-service-card:hover p {
  color: #333;
}

.rs-service-read-more {
  margin-top: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #0b1c3d;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.7;
  transition: all 0.3s ease;
  align-self: flex-start;
}

.rs-service-read-more i {
  font-size: 0.7rem;
  transition: transform 0.3s ease;
}

.rs-luxury-service-card:hover .rs-service-read-more {
  opacity: 1;
}

.rs-luxury-service-card:hover .rs-service-read-more i {
  transform: translateX(5px);
}

/* 服务卡片交替视觉效果 */
.rs-luxury-service-card:nth-child(even) .rs-service-icon {
  order: 1;
  margin-left: 1rem;
}

.rs-luxury-service-card:nth-child(even) .rs-service-separator {
  margin-left: auto;
}

.rs-luxury-service-card:nth-child(even):hover .rs-service-separator {
  margin-left: auto;
  margin-right: 0;
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .rs-luxury-services-grid {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 3rem;
  }
  
  .rs-luxury-service-card h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .rs-luxury-services-container {
    padding: 5rem 1.5rem;
  }
  
  .rs-section-title {
    margin-bottom: 5rem;
  }
  
  .rs-luxury-services-grid {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
  
  .rs-service-image-container {
    height: 250px;
  }
}

/* 豪华联系部分 */
.rs-luxury-contact-section {
  position: relative;
  background-image: linear-gradient(rgba(11, 28, 61, 0.85), rgba(0, 0, 0, 0.85)), url('../assets/images/residential_sell/001.jpeg');
  background-size: cover;
  background-position: center;
  padding: 10rem 2rem;
  text-align: center;
  color: white;
}

.rs-contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(11, 28, 61, 0.4);
}

.rs-luxury-contact-content {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  z-index: 10;
}

.rs-luxury-contact-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.rs-contact-separator {
  height: 2px;
  width: 100px;
  background: #CFB483;
  margin: 0 auto 2rem;
}

.rs-luxury-contact-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 3rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
}

.rs-luxury-cta-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.rs-luxury-primary-btn, .rs-luxury-secondary-btn {
  padding: 1.2rem 2.5rem;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.rs-luxury-primary-btn {
  background: transparent;
  color: white;
  border: 1px solid #CFB483;
}

.rs-luxury-primary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #CFB483;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: -1;
}

.rs-luxury-primary-btn:hover::before {
  width: 100%;
}

.rs-luxury-primary-btn:hover {
  color: #111;
}

.rs-luxury-secondary-btn {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.rs-luxury-secondary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: -1;
}

.rs-luxury-secondary-btn:hover::before {
  width: 100%;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .rs-luxury-hero-content h1 {
    font-size: 3rem;
  }
  
  .rs-luxury-hero-content h2 {
    font-size: 1.8rem;
  }
  
  .rs-section-title, .rs-luxury-introduction h2 {
    font-size: 2.2rem;
  }
  
  .rs-luxury-contact-section h2 {
    font-size: 2.5rem;
  }
  
  .rs-luxury-services-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .rs-luxury-hero-section {
    height: 80vh;
  }
  
  .rs-luxury-hero-content h1 {
    font-size: 2.5rem;
    letter-spacing: 6px;
  }
  
  .rs-luxury-hero-content h2 {
    font-size: 1.5rem;
    letter-spacing: 2px;
  }
  
  .rs-shape-1, .rs-shape-2 {
    opacity: 0.06;
  }
  
  .rs-luxury-introduction, .rs-luxury-services-container {
    padding: 5rem 1.5rem;
  }
  
  .rs-section-title, .rs-luxury-introduction h2 {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  
  .rs-luxury-services-grid {
    grid-template-columns: 1fr;
  }
  
  .rs-luxury-cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }
  
  .rs-luxury-primary-btn, .rs-luxury-secondary-btn {
    width: 100%;
  }
  
  .rs-luxury-contact-section h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 480px) {
  .rs-luxury-hero-content h1 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
  
  .rs-luxury-hero-content h2 {
    font-size: 1.3rem;
  }
  
  .rs-luxury-introduction p, .rs-luxury-hero-content p, .rs-luxury-contact-section p {
    font-size: 1rem;
  }
} 