/* Premium Rental Management页面样式 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

.prm-premium-rental-management {
  width: 100%;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  color: #222;
  background-color: #fbfbfb;
}

/* 豪华英雄部分 - 高级设计背景 */
.prm-premium-hero-section {
  height: 90vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* 设计性背景 */
.prm-designer-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

/* 渐变背景叠加层 - 使用蓝色系渐变 */
.prm-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(145deg, 
    #030e24 0%, 
    #0a1c3d 35%, 
    #0e2042 45%, 
    #071630 65%, 
    #030c1d 100%
  );
  z-index: 1;
}

/* 光线效果 */
.prm-pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(ellipse at 15% 15%, rgba(207, 180, 131, 0.08) 0%, transparent 60%),
    radial-gradient(ellipse at 85% 85%, rgba(207, 180, 131, 0.08) 0%, transparent 60%),
    linear-gradient(135deg, 
      rgba(207, 180, 131, 0.02) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(207, 180, 131, 0.02) 50%, 
      rgba(207, 180, 131, 0.02) 75%, 
      transparent 75%, 
      transparent
    );
  background-size: 100% 100%, 100% 100%, 60px 60px;
  opacity: 1;
  z-index: 2;
}

/* 几何图形装饰 */
.prm-animated-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

/* 奢华金线框架 */
.prm-shape {
  position: absolute;
  border-radius: 0;
  opacity: 0.15;
  border: 1px solid #CFB483;
  box-shadow: 0 0 30px rgba(207, 180, 131, 0.1);
  backdrop-filter: blur(3px);
  transform-origin: center;
}

.prm-shape-1 {
  width: 35vw;
  height: 35vw;
  top: -10%;
  right: -5%;
  border-radius: 44% 56% 70% 30% / 30% 40% 60% 70%;
  border-width: 1px;
  background: radial-gradient(circle, rgba(207, 180, 131, 0.03) 0%, rgba(207, 180, 131, 0) 70%);
  animation: morphShape1 25s ease-in-out infinite alternate;
  transform: rotate(25deg);
}

.prm-shape-2 {
  width: 30vw;
  height: 30vw;
  bottom: -5%;
  left: -5%;
  border-radius: 73% 27% 59% 41% / 57% 43% 57% 43%;
  background: radial-gradient(circle, rgba(11, 28, 61, 0.5) 0%, rgba(11, 28, 61, 0) 70%);
  animation: morphShape2 20s ease-in-out infinite alternate;
  transform: rotate(-15deg);
}

.prm-shape-3 {
  width: 15vw;
  height: 15vw;
  top: 40%;
  left: 20%;
  border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
  background: radial-gradient(circle, rgba(207, 180, 131, 0.02) 0%, rgba(207, 180, 131, 0) 70%);
  animation: morphShape3 15s ease-in-out infinite alternate;
  transform: rotate(45deg);
}

/* 添加金色装饰线条 */
.prm-designer-background::before,
.prm-designer-background::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, rgba(207, 180, 131, 0) 0%, rgba(207, 180, 131, 0.3) 50%, rgba(207, 180, 131, 0) 100%);
  height: 1px;
  width: 80%;
  left: 10%;
  z-index: 4;
}

.prm-designer-background::before {
  top: 15%;
  animation: fadeLineX 8s ease-in-out infinite alternate;
}

.prm-designer-background::after {
  bottom: 15%;
  animation: fadeLineX 8s ease-in-out 1s infinite alternate;
}

/* 垂直线条 */
.prm-gradient-overlay::before,
.prm-gradient-overlay::after {
  content: '';
  position: absolute;
  background: linear-gradient(180deg, rgba(207, 180, 131, 0) 0%, rgba(207, 180, 131, 0.2) 50%, rgba(207, 180, 131, 0) 100%);
  width: 1px;
  height: 70%;
  top: 15%;
  z-index: 4;
}

.prm-gradient-overlay::before {
  left: 25%;
  animation: fadeLineY 10s ease-in-out infinite alternate;
}

.prm-gradient-overlay::after {
  right: 25%;
  animation: fadeLineY 10s ease-in-out 1.5s infinite alternate;
}

/* 3D光点效果 */
.prm-pattern-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(1px 1px at 20% 30%, rgba(255, 255, 255, 0.15) 0%, transparent 100%),
    radial-gradient(1px 1px at 40% 70%, rgba(255, 255, 255, 0.1) 0%, transparent 100%),
    radial-gradient(1px 1px at 60% 40%, rgba(255, 255, 255, 0.1) 0%, transparent 100%),
    radial-gradient(1px 1px at 80% 10%, rgba(255, 255, 255, 0.12) 0%, transparent 100%),
    radial-gradient(1px 1px at 85% 60%, rgba(255, 255, 255, 0.08) 0%, transparent 100%),
    radial-gradient(1px 1px at 10% 60%, rgba(255, 255, 255, 0.08) 0%, transparent 100%),
    radial-gradient(1px 1px at 30% 90%, rgba(255, 255, 255, 0.1) 0%, transparent 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 2;
  animation: twinkling 10s ease-in-out infinite alternate;
}

/* 英雄内容 */
.prm-premium-hero-content {
  position: relative;
  text-align: center;
  color: white;
  padding: 3rem;
  max-width: 900px;
  z-index: 10;
}

.prm-premium-hero-content h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  font-weight: 200;
  letter-spacing: 8px;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  background: linear-gradient(45deg, #fff, #CFB483);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
}

.prm-premium-hero-content h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 1px;
  background: linear-gradient(90deg, rgba(207, 180, 131, 0.1), rgba(207, 180, 131, 1), rgba(207, 180, 131, 0.1));
}

.prm-premium-hero-content h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  color: rgba(255, 255, 255, 0.9);
}

.prm-premium-hero-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 2.5rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.prm-premium-cta-button {
  background: transparent;
  border: 1px solid rgba(207, 180, 131, 0.6);
  color: white;
  padding: 0.9rem 2.2rem;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.prm-premium-cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(207, 180, 131, 0.8);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: -1;
}

.prm-premium-cta-button:hover::before {
  width: 100%;
}

.prm-premium-cta-button:hover {
  color: #111;
  border-color: rgba(207, 180, 131, 0.8);
}

/* 标准服务部分 - 创新设计 */
.prm-standard-services-section {
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
  background-color: #fafafa;
}

.prm-services-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.prm-gradient-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 20% 80%, rgba(11, 28, 61, 0.03) 0%, transparent 60%),
              radial-gradient(circle at 80% 20%, rgba(207, 180, 131, 0.03) 0%, transparent 60%);
}

.prm-pattern-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  background-image: 
    linear-gradient(30deg, rgba(207, 180, 131, 0.03) 12%, transparent 12.5%, transparent 87%, rgba(207, 180, 131, 0.03) 87.5%, rgba(207, 180, 131, 0.03)),
    linear-gradient(150deg, rgba(207, 180, 131, 0.03) 12%, transparent 12.5%, transparent 87%, rgba(207, 180, 131, 0.03) 87.5%, rgba(207, 180, 131, 0.03)),
    linear-gradient(30deg, rgba(207, 180, 131, 0.03) 12%, transparent 12.5%, transparent 87%, rgba(207, 180, 131, 0.03) 87.5%, rgba(207, 180, 131, 0.03)),
    linear-gradient(150deg, rgba(207, 180, 131, 0.03) 12%, transparent 12.5%, transparent 87%, rgba(207, 180, 131, 0.03) 87.5%, rgba(207, 180, 131, 0.03)),
    linear-gradient(60deg, rgba(11, 28, 61, 0.03) 25%, transparent 25.5%, transparent 75%, rgba(11, 28, 61, 0.03) 75%, rgba(11, 28, 61, 0.03)),
    linear-gradient(60deg, rgba(11, 28, 61, 0.03) 25%, transparent 25.5%, transparent 75%, rgba(11, 28, 61, 0.03) 75%, rgba(11, 28, 61, 0.03));
  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

.prm-section-container {
  position: relative;
  z-index: 1;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.prm-section-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 300;
  color: #0b1c3d;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: relative;
}

.prm-section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 1px;
  background: #CFB483;
}

.prm-section-title::before {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 1px;
  background: rgba(207, 180, 131, 0.3);
}

.prm-section-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 5rem;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

/* 六边形服务容器 */
.prm-standard-services-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 4rem;
  justify-items: center;
}

/* 六边形服务卡片 */
.prm-service-hexagon {
  width: 280px;
  height: 320px;
  position: relative;
  margin: 1.5rem 0;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.prm-service-hexagon:before,
.prm-service-hexagon:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}

.prm-service-hexagon:after {
  background: linear-gradient(135deg, rgba(207, 180, 131, 0.1), rgba(11, 28, 61, 0.1));
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  opacity: 0;
  z-index: 0;
}

.prm-service-hexagon:hover {
  transform: translateY(-15px);
}

.prm-service-hexagon:hover:before {
  transform: scale(1.03);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  background: white;
}

.prm-service-hexagon:hover:after {
  opacity: 1;
  transform: scale(1.08) rotate(3deg);
}

.prm-hexagon-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2.5rem 2rem;
  z-index: 2;
  color: #0b1c3d;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.prm-service-icon {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(11, 28, 61, 0.05);
  border-radius: 50%;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #0b1c3d;
  transition: all 0.5s ease;
  position: relative;
  z-index: 5;
}

.prm-service-hexagon:hover .prm-service-icon {
  background: #0b1c3d;
  color: white;
  transform: scale(1.2) rotate(360deg);
}

.prm-service-id {
  position: absolute;
  top: 10px;
  right: 25%;
  font-size: 5rem;
  font-weight: 200;
  color: rgba(207, 180, 131, 0.1);
  line-height: 1;
  z-index: 3;
  transition: all 0.5s ease;
}

.prm-service-hexagon:hover .prm-service-id {
  color: rgba(207, 180, 131, 0.2);
  transform: scale(1.1) rotate(5deg);
}

.prm-service-hexagon h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
  color: #0b1c3d;
  position: relative;
  transition: all 0.5s ease;
  z-index: 4;
}

.prm-service-description {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  margin-top: 0;
}

.prm-service-hexagon:hover .prm-service-description {
  height: auto;
  opacity: 1;
  margin-top: 0.75rem;
}

.prm-service-description p {
  font-size: 0.85rem;
  line-height: 1.5;
  color: #555;
  margin: 0;
}

/* 交错排列 */
.prm-service-hexagon:nth-child(4n+2),
.prm-service-hexagon:nth-child(4n+4) {
  margin-top: 4rem;
}

/* 中央箭头提示 */
.prm-services-cta {
  text-align: center;
  margin: 3rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #0b1c3d;
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: 1px;
}

.prm-arrow-down {
  margin-top: 1.5rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(207, 180, 131, 0.1);
  border-radius: 50%;
  color: #0b1c3d;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

/* 响应式布局 */
@media (max-width: 1400px) {
  .prm-standard-services-container {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .prm-service-hexagon:nth-child(4n+2),
  .prm-service-hexagon:nth-child(4n+4) {
    margin-top: 1.5rem;
  }
  
  .prm-service-hexagon:nth-child(3n+2) {
    margin-top: 4rem;
  }
}

@media (max-width: 992px) {
  .prm-standard-services-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .prm-service-hexagon:nth-child(3n+2) {
    margin-top: 1.5rem;
  }
  
  .prm-service-hexagon:nth-child(2n+2) {
    margin-top: 4rem;
  }
}

@media (max-width: 768px) {
  .prm-standard-services-section {
    padding: 5rem 1.5rem;
  }
  
  .prm-standard-services-container {
    grid-template-columns: 1fr;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .prm-service-hexagon {
    width: 280px;
    height: 320px;
    margin: 1.5rem auto;
  }
  
  .prm-service-hexagon:nth-child(2n+2) {
    margin-top: 1.5rem;
  }
  
  .prm-service-description {
    height: auto;
    opacity: 1;
    margin-top: 0.75rem;
  }
}

@media (max-width: 480px) {
  .prm-service-hexagon {
    width: 250px;
    height: 290px;
  }
  
  .prm-service-icon {
    height: 50px;
    width: 50px;
    font-size: 1.25rem;
  }
  
  .prm-service-id {
    font-size: 4rem;
  }
}

/* 视觉分隔部分 */
.prm-visual-divider {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.prm-parallax-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.prm-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(11, 28, 61, 0.7);
  z-index: 1;
}

.prm-content-container {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
}

.prm-visual-divider h2 {
  font-size: 3rem;
  font-weight: 200;
  margin: 0;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.prm-gold-separator {
  height: 2px;
  background: #CFB483;
  margin: 1.5rem auto;
}

.prm-visual-divider h3 {
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
}

/* 扩展服务部分 */
.prm-extended-services-section {
  padding: 8rem 2rem;
  background: linear-gradient(to bottom, #f9f9f9, #fff);
  position: relative;
}

.prm-extended-services-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 5rem;
}

/* 图片展示部分 */
.prm-image-showcase {
  position: relative;
}

.prm-image-stack {
  position: relative;
  height: 100%;
}

.prm-image {
  position: absolute;
  border-radius: 0;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.prm-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prm-image-1 {
  width: 70%;
  height: 60%;
  top: 0;
  left: 0;
  z-index: 2;
}

.prm-image-2 {
  width: 60%;
  height: 70%;
  bottom: 0;
  right: 0;
  z-index: 1;
}

/* 扩展服务列表 */
.prm-extended-services-list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.prm-extended-service-card {
  display: flex;
  gap: 2rem;
  position: relative;
  transition: all 0.5s ease;
}

.prm-extended-service-card:hover {
  transform: translateX(10px);
}

.prm-service-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  background: rgba(11, 28, 61, 0.05);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: #0b1c3d;
  transition: all 0.5s ease;
}

.prm-extended-service-card:hover .prm-service-icon {
  background: #0b1c3d;
  color: white;
}

.prm-service-content {
  display: flex;
  flex-direction: column;
}

.prm-service-content h3 {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 0 1rem 0;
  color: #0b1c3d;
}

.prm-service-separator {
  width: 50px;
  height: 1px;
  background: rgba(207, 180, 131, 0.5);
  margin-bottom: 1rem;
  transition: all 0.5s ease;
}

.prm-extended-service-card:hover .prm-service-separator {
  width: 80px;
  background: #CFB483;
}

.prm-service-content p {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
  margin: 0;
}

/* 联系部分 */
.prm-premium-contact-section {
  position: relative;
  background-image: linear-gradient(rgba(11, 28, 61, 0.85), rgba(0, 0, 0, 0.85)), url('../assets/images/premium_rental_management/003.jpeg');
  background-size: cover;
  background-position: center;
  padding: 10rem 2rem;
  text-align: center;
  color: white;
}

.prm-contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(11, 28, 61, 0.4);
}

.prm-premium-contact-content {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  z-index: 10;
}

.prm-premium-contact-section h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.prm-contact-separator {
  height: 2px;
  width: 100px;
  background: #CFB483;
  margin: 0 auto 2rem;
}

.prm-premium-contact-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 3rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
}

.prm-premium-cta-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.prm-premium-primary-btn, .prm-premium-secondary-btn {
  padding: 1.2rem 2.5rem;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.prm-premium-primary-btn {
  background: transparent;
  color: white;
  border: 1px solid #CFB483;
}

.prm-premium-primary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #CFB483;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: -1;
}

.prm-premium-primary-btn:hover::before {
  width: 100%;
}

.prm-premium-primary-btn:hover {
  color: #111;
}

.prm-premium-secondary-btn {
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.prm-premium-secondary-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: -1;
}

.prm-premium-secondary-btn:hover::before {
  width: 100%;
}

/* 变形动画 */
@keyframes morphShape1 {
  0% {
    border-radius: 44% 56% 70% 30% / 30% 40% 60% 70%;
    transform: rotate(25deg) translateY(0);
  }
  50% {
    border-radius: 60% 40% 30% 70% / 50% 60% 40% 50%;
  }
  100% {
    border-radius: 30% 70% 50% 50% / 40% 30% 70% 60%;
    transform: rotate(15deg) translateY(40px);
  }
}

@keyframes morphShape2 {
  0% {
    border-radius: 73% 27% 59% 41% / 57% 43% 57% 43%;
    transform: rotate(-15deg) translateY(0);
  }
  50% {
    border-radius: 40% 60% 30% 70% / 40% 60% 40% 60%;
  }
  100% {
    border-radius: 60% 40% 40% 60% / 30% 70% 70% 30%;
    transform: rotate(-25deg) translateY(-40px);
  }
}

@keyframes morphShape3 {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
    transform: rotate(45deg) translate(0, 0);
  }
  50% {
    border-radius: 40% 60% 50% 50% / 60% 40% 60% 40%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 40% 60% 40% 60%;
    transform: rotate(30deg) translate(30px, 30px);
  }
}

@keyframes fadeLineX {
  0% {
    opacity: 0;
    transform: scaleX(0.6);
  }
  50% {
    opacity: 1;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(0.6);
  }
}

@keyframes fadeLineY {
  0% {
    opacity: 0;
    transform: scaleY(0.6);
  }
  50% {
    opacity: 0.7;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(0.6);
  }
}

@keyframes twinkling {
  0% {
    opacity: 0.3;
    transform: translateZ(0);
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
    transform: translateZ(0);
  }
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .prm-extended-services-container {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
  
  .prm-image-stack {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .prm-premium-hero-section {
    height: 80vh;
  }
  
  .prm-premium-hero-content h1 {
    font-size: 2.5rem;
    letter-spacing: 6px;
  }
  
  .prm-premium-hero-content h2 {
    font-size: 1.5rem;
    letter-spacing: 2px;
  }
  
  .prm-standard-services-section,
  .prm-extended-services-section {
    padding: 5rem 1.5rem;
  }
  
  .prm-section-title {
    font-size: 2rem;
  }
  
  .prm-standard-services-grid {
    grid-template-columns: 1fr 1fr;
  }
  
  .prm-image-stack {
    height: 400px;
  }
  
  .prm-extended-service-card {
    flex-direction: column;
    gap: 1rem;
  }
  
  .prm-service-icon {
    margin-bottom: 0.5rem;
  }
  
  .prm-premium-contact-section h2 {
    font-size: 2.2rem;
  }
  
  .prm-premium-cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .prm-premium-hero-content h1 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
  
  .prm-premium-hero-content h2 {
    font-size: 1.3rem;
  }
  
  .prm-section-title {
    font-size: 1.8rem;
  }
  
  .prm-standard-services-grid {
    grid-template-columns: 1fr;
  }
  
  .prm-image-stack {
    height: 350px;
  }
  
  .prm-visual-divider {
    height: 300px;
  }
  
  .prm-visual-divider h2 {
    font-size: 2.2rem;
  }
  
  .prm-visual-divider h3 {
    font-size: 1.4rem;
  }
} 