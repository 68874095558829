.home-page {
  padding-top: 0; /* 移除顶部padding */
}

.hero-section {
  height: 100vh;
  background-color: #001233; /* 深蓝色替代纯黑色 */
  background-image: linear-gradient(rgba(0, 18, 51, 0.9), rgba(0, 0, 0, 0.9)); /* 添加深蓝色渐变 */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100vw; /* 确保占满整个视口宽度 */
  max-width: 100%; /* 防止溢出 */
  left: 0;
  right: 0;
  transition: background-color 2s ease;
  -webkit-font-smoothing: antialiased; /* 文字渲染优化 */
  -moz-osx-font-smoothing: grayscale; /* 文字渲染优化 */
}

.hero-content {
  text-align: center;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  position: relative;
  z-index: 10;
}

/* Hero轮播样式 */
.hero-slide-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

.hero-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  backface-visibility: hidden;
  perspective: 1000px;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0) scale(1.1); /* 使用translate3d触发GPU加速 */
  will-change: transform, opacity;
  pointer-events: none;
}

.hero-slide.active {
  opacity: 1;
  visibility: visible;
  z-index: 3;
  animation: slideActive 2.5s forwards cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: auto;
}

.hero-slide.leaving {
  z-index: 2;
  visibility: visible;
  animation: slideLeaving 2.5s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

/* 使用精细的贝塞尔曲线提高动画流畅度 */
@keyframes slideActive {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(1.05);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes slideLeaving {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.95);
  }
}

/* 同样优化背景图片动画 */
.hero-slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  will-change: transform, opacity;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0) scale(1.5);
}

.hero-slide.active .hero-slide-bg {
  animation: bgActive 10s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-slide.leaving .hero-slide-bg {
  animation: bgLeaving 2.5s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes bgActive {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(1.5);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.3);
  }
}

@keyframes bgLeaving {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.3);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(1.4);
  }
}

/* 为不同幻灯片设置不同背景位置 */
.hero-slide:nth-child(1) .hero-slide-bg {
  background-position: center 20%;
}

.hero-slide:nth-child(2) .hero-slide-bg {
  background-position: right center;
}

.hero-slide:nth-child(3) .hero-slide-bg {
  background-position: left center;
}

/* 文本元素的动画 */
.hero-title, .hero-subtitle, .hero-description, .hero-explore-btn {
  backface-visibility: hidden; /* 优化性能 */
  will-change: transform, opacity; /* 优化性能 */
}

/* 确保next状态透明度为0 */
.hero-slide.next {
  visibility: visible;
  opacity: 0;
  z-index: 1;
}

/* 调整文字渐出效果 */
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
}

/* 调整预加载样式 */
.hero-slide.next .hero-slide-bg {
  opacity: 0;
  transition: opacity 1s ease;
}

/* 优化活动幻灯片过渡 */
.hero-slide.active .hero-slide-bg {
  opacity: 1;
  transition: transform 10s ease, opacity 2.5s ease;
}

/* 确保只有在活动幻灯片上应用Ken Burns效果 */
.hero-slide.active .hero-slide-bg {
  animation: none; /* 先清除之前的动画 */
}

/* 为不同轮播项设置不同的Ken Burns效果 */
.hero-slide:nth-child(1) .hero-slide-bg {
  background-position: center 20%;
}

.hero-slide:nth-child(2) .hero-slide-bg {
  background-position: right center;
}

.hero-slide:nth-child(3) .hero-slide-bg {
  background-position: left center;
}

.hero-slide:nth-child(1).active .hero-slide-bg {
  animation: kenburnsTop 20s forwards ease-in-out;
}

.hero-slide:nth-child(2).active .hero-slide-bg {
  animation: kenburnsRight 20s forwards ease-in-out;
}

.hero-slide:nth-child(3).active .hero-slide-bg {
  animation: kenburnsLeft 20s forwards ease-in-out;
}

@keyframes kenburnsTop {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2) translateY(-20px);
  }
}

@keyframes kenburnsRight {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2) translateX(-20px);
  }
}

@keyframes kenburnsLeft {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2) translateX(20px);
  }
}

.hero-slide.active .hero-title {
  animation: fadeInUp 1.5s 0.3s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-slide.active .hero-subtitle {
  animation: fadeInUp 1.5s 0.6s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-slide.active .hero-description {
  animation: fadeInUp 1.5s 0.9s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-slide.active .hero-explore-btn {
  animation: fadeInUp 1.5s 1.2s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

/* 添加幻灯片文字淡出动画 */
.hero-slide.leaving .hero-title {
  animation: fadeOutDown 1s forwards;
}

.hero-slide.leaving .hero-subtitle {
  animation: fadeOutDown 1s 0.1s forwards;
}

.hero-slide.leaving .hero-description {
  animation: fadeOutDown 1s 0.2s forwards;
}

.hero-slide.leaving .hero-explore-btn {
  animation: fadeOutDown 1s 0.3s forwards;
}

/* 修改文字淡入淡出动画为GPU加速版本 */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
}

.hero-title {
  font-size: 4rem;
  letter-spacing: 12px;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: translateY(30px);
  position: relative;
  z-index: 10;
}

.hero-subtitle {
  font-size: 1.5rem;
  letter-spacing: 3px;
  margin-bottom: 2rem;
  font-weight: 300;
  max-width: 800px;
  opacity: 0;
  transform: translateY(30px);
  color: #CFB483; /* 金色 */
  position: relative;
  z-index: 10;
}

.hero-slide.active .hero-subtitle {
  animation: fadeInUp 1.5s 0.6s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-description {
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 2.5rem;
  opacity: 0;
  transform: translateY(30px);
  position: relative;
  z-index: 10;
}

.hero-slide.active .hero-description {
  animation: fadeInUp 1.5s 0.9s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-explore-btn {
  padding: 12px 30px;
  background: transparent;
  color: #fff;
  border: 2px solid #CFB483;
  font-size: 1rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(30px);
  z-index: 10;
}

.hero-slide.active .hero-explore-btn {
  animation: fadeInUp 1.5s 1.2s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-explore-btn:hover {
  background: #CFB483;
  color: #091738;
}

.hero-explore-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(207, 180, 131, 0.3);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
  z-index: -1;
}

.hero-explore-btn:hover::before {
  transform: translateX(0);
}

/* 响应式设计 */
@media (max-width: 992px) {
  .hero-title {
    font-size: 3rem;
    letter-spacing: 8px;
  }
  
  .hero-subtitle {
    font-size: 1.3rem;
    letter-spacing: 2px;
  }
  
  .hero-description {
    font-size: 1rem;
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
    letter-spacing: 6px;
  }
  
  .hero-subtitle {
    font-size: 1.1rem;
    letter-spacing: 1.5px;
  }
  
  .hero-slide-container {
    height: 70vh;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
    letter-spacing: 4px;
  }
  
  .hero-subtitle {
    font-size: 1rem;
    letter-spacing: 1px;
  }
  
  .hero-description {
    font-size: 0.9rem;
  }
  
  .hero-explore-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

/* 发现部分样式 */
.discover-section {
  padding: 5rem 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 3rem; /* 添加底部间距 */
}

.section-divider {
  margin-bottom: 2rem;
}

.divider-icon {
  color: #CFB483;
  font-size: 1.5rem;
  letter-spacing: 5px;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  letter-spacing: 3px;
  color: #091738;
}

.section-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  max-width: 900px;
  margin: 0 auto;
}

/* 联系部分样式 */
.contact-section {
  padding: 5rem 2rem;
  text-align: center;
  background-color: #FFFFFF;
  position: relative;
  box-shadow: none; /* 移除阴影 */
  background-image: none !important;
  margin-top: 3rem; /* 添加顶部间距 */
}

.contact-section::before {
  content: none !important; /* 移除任何可能的伪元素背景 */
}

.contact-subtitle {
  color: #CFB483;
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

.contact-title {
  font-size: 2.5rem;
  margin-bottom: 4rem;
  color: #091738;
}

/* 统计数据样式 */
.stats-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.stat-item {
  padding: 2rem;
  min-width: 200px;
  margin-bottom: 2rem;
  transition: all 0.4s ease;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px rgba(9, 23, 56, 0.08);
  border: 1px solid rgba(207, 180, 131, 0.1);
}

/* 聚光灯效果 */
.stat-item::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(224, 207, 131, 0.15) 0%, rgba(255, 255, 255, 0) 60%);
  opacity: 0;
  z-index: -1;
  transform: scale(0.5);
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.stat-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(9, 23, 56, 0.1);
}

.stat-item:hover::before {
  opacity: 1;
  transform: scale(1) rotate(45deg);
  animation: spotlight 3s infinite alternate;
}

@keyframes spotlight {
  0% {
    transform: translate(-10%, -10%) scale(1);
  }
  100% {
    transform: translate(10%, 10%) scale(1);
  }
}

.stat-item:hover .stat-label {
  color: #333;
  font-weight: 500;
  transition: all 0.3s ease;
}

.stat-number {
  font-size: 2.5rem;
  color: #CFB483;
  margin-bottom: 0.5rem;
  position: relative;
}

.count-animation {
  color: #CFB483;
  display: inline-block;
  position: relative;
  transition: all 0.5s ease;
}

/* 修改数字hover特效 - 更简单大气 */
.stat-item:hover .count-animation {
  background: linear-gradient(45deg, #E0CF83, #CFB483, #905E26);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transform: scale(1.1);
  text-shadow: 0 0 1px rgba(207, 180, 131, 0.2);
  letter-spacing: 1px;
}

.stat-label {
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: #444;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.stat-item[data-aos] {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.stat-item[data-aos].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .stats-container {
    flex-direction: column;
    align-items: center;
  }
  
  .section-title, .contact-title {
    font-size: 2rem;
  }
  
  .stat-number {
    font-size: 2rem;
  }
}

/* 黄金价值部分样式 */
.gold-value-section {
  padding: 5rem 2rem;
  text-align: center;
  background-color: #FFFFFF;
  position: relative;
}

.gold-value-section::before {
  content: none;
}

.gold-highlights {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 3rem auto 0;
  position: relative;
  z-index: 1;
}

/* 基础卡片样式 */
.gold-highlight-item {
  width: 320px;
  margin: 1.5rem;
  padding: 2.5rem;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(9, 23, 56, 0.08);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  position: relative;
  cursor: pointer;
  z-index: 1;
  border: 1px solid rgba(207, 180, 131, 0.1);
  overflow: visible;
}

.gold-highlight-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #CFB483, #E0CF83);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.gold-highlight-item .card-glow {
  display: none;
}

.gold-highlight-item:hover::before,
.gold-highlight-item.active::before {
  transform: scaleX(1);
}

.gold-highlight-item:hover .card-glow,
.gold-highlight-item.active .card-glow {
  display: none;
}

.highlight-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #905E26, #CFB483);
  border-radius: 50%;
  color: #FFFFFF;
  font-size: 2rem;
  transition: all 0.5s ease;
  box-shadow: 0 10px 20px rgba(144, 94, 38, 0.2);
  position: relative;
  overflow: hidden;
}

.highlight-icon::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: 140%;
  height: 140%;
  background: none;
  transform: none;
  z-index: 1;
  animation: none;
}

@keyframes shimmer {
  0% {
    transform: rotate(45deg) translateX(-30%);
  }
  100% {
    transform: rotate(45deg) translateX(30%);
  }
}

.gold-highlight-item:hover .highlight-icon::before,
.gold-highlight-item.active .highlight-icon::before {
  transform: rotate(45deg) translateX(100%);
}

.gold-highlight-item:hover .highlight-icon,
.gold-highlight-item.active .highlight-icon {
  transform: scale(1.1) rotateY(180deg);
  background: linear-gradient(45deg, #CFB483, #E0CF83);
  box-shadow: 0 15px 30px rgba(207, 180, 131, 0.4), 0 0 0 15px rgba(207, 180, 131, 0.1);
}

.gold-highlight-item h3 {
  font-size: 1.5rem;
  color: #091738;
  margin-bottom: 1rem;
  transition: all 0.4s ease;
  position: relative;
  display: inline-block;
}

.gold-highlight-item h3::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #CFB483, #E0CF83);
  transform: translateX(-50%);
  transition: width 0.4s ease;
  opacity: 0;
}

.gold-highlight-item:hover h3,
.gold-highlight-item.active h3 {
  color: #091738;
  transform: translateY(-5px);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.gold-highlight-item:hover h3::after,
.gold-highlight-item.active h3::after {
  width: 70%;
  opacity: 1;
}

.highlight-brief {
  color: #666;
  line-height: 1.6;
  transition: all 0.4s ease;
  max-width: 85%;
  margin: 0 auto;
}

.gold-highlight-item:hover .highlight-brief,
.gold-highlight-item.active .highlight-brief {
  color: #091738;
  font-weight: 500;
  transform: translateY(-5px);
}

/* 详情内容基础样式 */
.highlight-details {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
  z-index: 10;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(9, 23, 56, 0.15);
  border: 1px solid rgba(207, 180, 131, 0.2);
  padding: 1.5rem;
  width: 280px;
  max-height: 0;
  pointer-events: none;
}

.gold-highlight-item:hover .highlight-details,
.gold-highlight-item.active .highlight-details {
  opacity: 1;
  max-height: 600px;
  pointer-events: all;
}

.highlight-details p {
  margin-bottom: 1rem;
  color: #555;
  font-size: 0.95rem;
  line-height: 1.7;
  position: relative;
  padding-left: 1.5rem;
  transform: translateY(10px);
  opacity: 0;
  transition: all 0.4s ease;
  transition-delay: 0.1s;
}

.highlight-details p:nth-child(2) {
  transition-delay: 0.2s;
}

.highlight-details p:nth-child(3) {
  transition-delay: 0.3s;
  margin-bottom: 0.5rem;
}

.gold-highlight-item:hover .highlight-details p,
.gold-highlight-item.active .highlight-details p {
  transform: translateY(0);
  opacity: 1;
}

.highlight-details p:last-child {
  margin-bottom: 0;
}

.highlight-details p::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(45deg, #CFB483, #E0CF83);
  box-shadow: 0 0 0 2px rgba(207, 180, 131, 0.2);
}

/* 添加详情查看提示 */
.gold-highlight-item .view-more-indicator {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2rem;
  color: #CFB483;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.gold-highlight-item:hover .view-more-indicator,
.gold-highlight-item.active .view-more-indicator {
  opacity: 0;
}

/* 左侧卡片特有样式 - 向右展开 */
.gold-highlight-item.position-left {
  transform-origin: left center;
}

.gold-highlight-item.position-left .highlight-details {
  left: 100%;
  top: 0;
  margin-left: 20px;
  transform: translateX(-20px);
  text-align: left;
}

.gold-highlight-item.position-left:hover .highlight-details,
.gold-highlight-item.position-left.active .highlight-details {
  transform: translateX(0);
}

.gold-highlight-item.position-left:hover,
.gold-highlight-item.position-left.active {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 15px 25px 50px rgba(9, 23, 56, 0.15);
  z-index: 5;
}

.gold-highlight-item.position-left .view-more-indicator {
  right: 1rem;
  left: auto;
  transform: none;
}

.gold-highlight-item.position-left.active .view-more-indicator,
.gold-highlight-item.position-left:hover .view-more-indicator {
  right: 1.5rem;
  opacity: 0.4;
  transform: translateX(10px);
}

.gold-highlight-item.position-left::after {
  content: '';
  position: absolute;
  top: 30%;
  right: -10px;
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  transform: rotate(45deg);
  border-top: 1px solid rgba(207, 180, 131, 0.2);
  border-right: 1px solid rgba(207, 180, 131, 0.2);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 11;
}

.gold-highlight-item.position-left:hover::after,
.gold-highlight-item.position-left.active::after {
  opacity: 1;
}

/* 中间卡片特有样式 - 向两侧展开 */
.gold-highlight-item.position-center {
  transform-origin: center center;
}

.gold-highlight-item.position-center .highlight-details {
  display: none;
}

.gold-highlight-item.position-center:hover,
.gold-highlight-item.position-center.active {
  transform: translateY(-15px) scale(1.08);
  box-shadow: 0 25px 50px rgba(9, 23, 56, 0.15);
}

.gold-highlight-item.position-center .view-more-indicator {
  display: none;
}

.gold-highlight-item.position-center::after {
  display: none;
}

/* 右侧卡片特有样式 - 向左展开 */
.gold-highlight-item.position-right {
  transform-origin: right center;
}

.gold-highlight-item.position-right .highlight-details {
  right: 100%;
  top: 0;
  margin-right: 20px;
  transform: translateX(20px);
  text-align: right;
}

.gold-highlight-item.position-right .highlight-details p {
  padding-left: 0;
  padding-right: 1.5rem;
}

.gold-highlight-item.position-right .highlight-details p::before {
  left: auto;
  right: 0;
}

.gold-highlight-item.position-right:hover .highlight-details,
.gold-highlight-item.position-right.active .highlight-details {
  transform: translateX(0);
}

.gold-highlight-item.position-right:hover,
.gold-highlight-item.position-right.active {
  transform: translateY(-10px) scale(1.05);
  box-shadow: -15px 25px 50px rgba(9, 23, 56, 0.15);
  z-index: 5;
}

.gold-highlight-item.position-right .view-more-indicator {
  left: 1rem;
  right: auto;
  transform: none;
}

.gold-highlight-item.position-right.active .view-more-indicator,
.gold-highlight-item.position-right:hover .view-more-indicator {
  left: 1.5rem;
  opacity: 0.4;
  transform: translateX(-10px);
}

.gold-highlight-item.position-right::after {
  content: '';
  position: absolute;
  top: 30%;
  left: -10px;
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  transform: rotate(45deg);
  border-bottom: 1px solid rgba(207, 180, 131, 0.2);
  border-left: 1px solid rgba(207, 180, 131, 0.2);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 11;
}

.gold-highlight-item.position-right:hover::after,
.gold-highlight-item.position-right.active::after {
  opacity: 1;
}

/* 移动端适配 */
@media (max-width: 992px) {
  .gold-highlight-item.position-center .highlight-details {
    width: 90vw;
    max-width: 460px;
    left: 50%;
    transform: translate(-50%, -20px);
  }
  
  .gold-highlight-item.position-center:hover .highlight-details,
  .gold-highlight-item.position-center.active .highlight-details {
    transform: translate(-50%, 0);
  }
}

@media (max-width: 768px) {
  .gold-highlight-item {
    width: 100%;
    max-width: 350px;
    margin: 1.5rem 0;
  }
  
  .gold-highlight-item.position-left .highlight-details,
  .gold-highlight-item.position-center .highlight-details,
  .gold-highlight-item.position-right .highlight-details {
    position: static;
    width: 100%;
    margin: 1.5rem 0 0;
    padding: 0;
    transform: none;
    box-shadow: none;
    border: none;
    border-top: 1px dashed rgba(207, 180, 131, 0.3);
    border-radius: 0;
    max-height: 0;
    padding-top: 0;
    background: transparent;
  }
  
  .gold-highlight-item.position-center .highlight-details {
    display: none;
  }
  
  .gold-highlight-item:hover .highlight-details,
  .gold-highlight-item.active .highlight-details {
    padding-top: 1.5rem;
  }
  
  .gold-highlight-item.position-left .highlight-details p,
  .gold-highlight-item.position-center .highlight-details p,
  .gold-highlight-item.position-right .highlight-details p {
    padding-left: 1.5rem;
    padding-right: 0;
    text-align: left;
  }
  
  .gold-highlight-item.position-center .highlight-details p::before,
  .gold-highlight-item.position-right .highlight-details p::before {
    left: 0;
    right: auto;
  }
  
  .gold-highlight-item.position-left::after,
  .gold-highlight-item.position-center::after,
  .gold-highlight-item.position-right::after {
    display: none;
  }
  
  .gold-highlight-item.position-left .view-more-indicator,
  .gold-highlight-item.position-right .view-more-indicator {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  
  .gold-highlight-item.position-left:hover .view-more-indicator,
  .gold-highlight-item.position-left.active .view-more-indicator,
  .gold-highlight-item.position-center:hover .view-more-indicator,
  .gold-highlight-item.position-center.active .view-more-indicator,
  .gold-highlight-item.position-right:hover .view-more-indicator,
  .gold-highlight-item.position-right.active .view-more-indicator {
    opacity: 0;
    transform: translateX(-50%);
  }
}

/* 轮播卡片部分样式 */
.carousel-section {
  padding: 5rem 0;
  text-align: center;
  position: relative;
  background-color: #FFFFFF;
  overflow: hidden;
}

.carousel-section::before {
  content: none;
}

.carousel-section::after {
  content: none;
}

.carousel-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 0 40px;
  perspective: 1200px;
  padding-bottom: 30px;
}

.carousel-container::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(to right, transparent, rgba(207, 180, 131, 0.5), transparent);
}

/* 轮播控制按钮样式 */
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #091738;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
}

.carousel-control:hover {
  background: linear-gradient(45deg, #CFB483, #E0CF83);
  color: #fff;
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 5px 20px rgba(207, 180, 131, 0.4);
}

.carousel-control.prev {
  left: 5px;
}

.carousel-control.next {
  right: 5px;
}

.carousel-track {
  display: flex;
  position: relative;
  transition: transform 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
  width: auto;
  padding: 60px 0;
  min-height: 300px;
}

.carousel-item {
  min-width: 20%;
  width: 20%;
  flex-shrink: 0;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1.2);
  transform-style: preserve-3d;
  filter: blur(1.5px);
  opacity: 0.65;
  transform: scale(0.85) translateY(10px);
  background: rgba(255, 255, 255, 0.95);
}

/* 轮播卡片特效样式 */
.carousel-item.center-active {
  transform: scale(1.15) translateZ(50px) translateY(-10px);
  opacity: 1;
  filter: none;
  z-index: 2;
  box-shadow: 0 15px 35px rgba(9, 23, 56, 0.1);
  background: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

.carousel-item.center-active .carousel-icon {
  background: linear-gradient(45deg, #091738, #3056B5);
  box-shadow: 0 10px 25px rgba(9, 23, 56, 0.3), 0 0 0 10px rgba(207, 180, 131, 0.1);
  transform: scale(1.1);
}

.carousel-item.center-active .carousel-title {
  color: #091738;
  font-weight: 600;
  transform: translateY(-5px);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel-item.center-active .carousel-title::after {
  width: 60px;
  background: linear-gradient(to right, #CFB483, #E0CF83);
  height: 3px;
  opacity: 1;
}

.carousel-item.center-active .carousel-content {
  color: #222;
  transform: translateY(-5px);
}

.carousel-item.side-active {
  transform: scale(0.95) translateY(0);
  opacity: 0.85;
  filter: blur(0.5px);
  background: rgba(255, 255, 255, 0.98);
}

.carousel-item.far-active {
  transform: scale(0.85) translateY(10px);
  opacity: 0.65;
  filter: blur(1.5px);
  background: rgba(255, 255, 255, 0.95);
}

.carousel-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #091738, #1e3a7b);
  color: #FFFFFF;
  border-radius: 50%;
  font-size: 1.5rem;
  margin-bottom: 1.2rem;
  box-shadow: 0 8px 20px rgba(9, 23, 56, 0.2);
  position: relative;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1.2);
}

.carousel-title {
  font-size: 1.3rem;
  color: #091738;
  margin-bottom: 0.9rem;
  position: relative;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  letter-spacing: 0.5px;
}

.carousel-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background: #CFB483;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.carousel-content {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #555;
  max-width: 100%;
  margin: 0 auto;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  backdrop-filter: blur(0);
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  background-color: rgba(9, 23, 56, 0.2);
  border-radius: 50%;
  margin: 0 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.carousel-dot::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #CFB483, #E0CF83);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
}

.carousel-dot.active {
  transform: scale(1.4);
}

.carousel-dot.active::before {
  opacity: 1;
}

.carousel-dot:hover::before {
  opacity: 0.7;
}

/* 响应式设计 - 轮播卡片和黄金价值部分 */
@media (max-width: 1200px) {
  .carousel-item {
    min-width: 25%;
    width: 25%;
  }
}

@media (max-width: 992px) {
  .carousel-item {
    min-width: 33.333%;
    width: 33.333%;
  }
}

@media (max-width: 768px) {
  .gold-highlights {
    flex-direction: column;
    align-items: center;
  }
  
  .gold-highlight-item {
    width: 100%;
    max-width: 350px;
    margin: 1.5rem 0;
  }
  
  .highlight-details {
    max-height: 0;
    opacity: 0;
  }
  
  .gold-highlight-item.active .highlight-details {
    max-height: 600px;
    opacity: 1;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
  
  .carousel-item {
    min-width: 50%;
    width: 50%;
  }
  
  .carousel-title {
    font-size: 1.2rem;
  }
  
  .carousel-content {
    font-size: 0.9rem;
  }
  
  .carousel-icon {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .carousel-item {
    min-width: 100%;
    width: 100%;
  }
}

/* 根据卡片位置调整展开方向 */
.gold-highlight-item.position-left {
  transform-origin: left center;
}

.gold-highlight-item.position-center {
  transform-origin: center center;
}

.gold-highlight-item.position-right {
  transform-origin: right center;
}

.gold-highlight-item.position-left:hover,
.gold-highlight-item.position-left.active {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 15px 25px 50px rgba(9, 23, 56, 0.15);
}

.gold-highlight-item.position-center:hover,
.gold-highlight-item.position-center.active {
  transform: translateY(-15px) scale(1.08);
  box-shadow: 0 25px 50px rgba(9, 23, 56, 0.15);
}

.gold-highlight-item.position-right:hover,
.gold-highlight-item.position-right.active {
  transform: translateY(-10px) scale(1.05);
  box-shadow: -15px 25px 50px rgba(9, 23, 56, 0.15);
}

/* 调整展开后的详情内容方向 */
.gold-highlight-item.position-left .highlight-details {
  text-align: left;
  transform-origin: left top;
}

.gold-highlight-item.position-center .highlight-details {
  text-align: center;
  transform-origin: center top;
}

.gold-highlight-item.position-center .highlight-details p {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.gold-highlight-item.position-center .highlight-details p::before {
  display: none;
}

.gold-highlight-item.position-center .highlight-details p::after {
  content: '';
  display: block;
  width: 30px;
  height: 1px;
  background: linear-gradient(to right, #CFB483, #E0CF83);
  margin: 10px auto;
}

.gold-highlight-item.position-right .highlight-details {
  text-align: right;
  transform-origin: right top;
}

.gold-highlight-item.position-right .highlight-details p {
  padding-left: 0;
  padding-right: 1.5rem;
}

.gold-highlight-item.position-right .highlight-details p::before {
  left: auto;
  right: 0;
}

/* 调整各位置卡片的展开动画效果 */
.gold-highlight-item.position-left:hover .highlight-details,
.gold-highlight-item.position-left.active .highlight-details {
  transform: translateX(10px);
}

.gold-highlight-item.position-center:hover .highlight-details,
.gold-highlight-item.position-center.active .highlight-details {
  transform: scale(1.02);
}

.gold-highlight-item.position-right:hover .highlight-details,
.gold-highlight-item.position-right.active .highlight-details {
  transform: translateX(-10px);
}

/* 根据位置自定义展开图标和提示 */
.gold-highlight-item.position-left .view-more-indicator {
  right: 1rem;
  left: auto;
  transform: none;
}

.gold-highlight-item.position-right .view-more-indicator {
  left: 1rem;
  right: auto;
  transform: none;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .gold-highlight-item.position-left,
  .gold-highlight-item.position-center,
  .gold-highlight-item.position-right {
    transform-origin: center top;
  }
  
  .gold-highlight-item.position-left:hover,
  .gold-highlight-item.position-left.active,
  .gold-highlight-item.position-center:hover,
  .gold-highlight-item.position-center.active,
  .gold-highlight-item.position-right:hover,
  .gold-highlight-item.position-right.active {
    transform: translateY(-10px) scale(1.03);
    box-shadow: 0 20px 40px rgba(9, 23, 56, 0.15);
  }
  
  .gold-highlight-item.position-left .highlight-details,
  .gold-highlight-item.position-center .highlight-details,
  .gold-highlight-item.position-right .highlight-details {
    text-align: left;
    transform-origin: top center;
  }
  
  .gold-highlight-item.position-center .highlight-details p,
  .gold-highlight-item.position-right .highlight-details p {
    padding-left: 1.5rem;
    padding-right: 0;
    text-align: left;
  }
  
  .gold-highlight-item.position-center .highlight-details p::before,
  .gold-highlight-item.position-right .highlight-details p::before {
    display: block;
    left: 0;
    right: auto;
  }
  
  .gold-highlight-item.position-center .highlight-details p::after {
    display: none;
  }
  
  .gold-highlight-item.position-left .view-more-indicator,
  .gold-highlight-item.position-right .view-more-indicator {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

/* 额外添加展开方向指示效果 */
.gold-highlight-item.position-left.active .view-more-indicator,
.gold-highlight-item.position-left:hover .view-more-indicator {
  right: 1.5rem;
  opacity: 0.4;
  transform: translateX(10px);
}

.gold-highlight-item.position-center.active .view-more-indicator,
.gold-highlight-item.position-center:hover .view-more-indicator {
  transform: scale(1.2) rotate(45deg);
  opacity: 0.4;
}

.gold-highlight-item.position-right.active .view-more-indicator,
.gold-highlight-item.position-right:hover .view-more-indicator {
  left: 1.5rem;
  opacity: 0.4;
  transform: translateX(-10px);
}

/* 卡片展开方向的过渡优化 */
.gold-highlight-item.position-left .highlight-details p {
  transform: translateX(-10px);
  opacity: 0;
  transition-delay: 0.1s;
}

.gold-highlight-item.position-left .highlight-details p:nth-child(2) {
  transition-delay: 0.2s;
}

.gold-highlight-item.position-left .highlight-details p:nth-child(3) {
  transition-delay: 0.3s;
}

.gold-highlight-item.position-left:hover .highlight-details p,
.gold-highlight-item.position-left.active .highlight-details p {
  transform: translateX(0);
  opacity: 1;
}

.gold-highlight-item.position-center .highlight-details p {
  transform: translateY(10px);
  opacity: 0;
  transition-delay: 0.1s;
}

.gold-highlight-item.position-center .highlight-details p:nth-child(2) {
  transition-delay: 0.2s;
}

.gold-highlight-item.position-center .highlight-details p:nth-child(3) {
  transition-delay: 0.3s;
}

.gold-highlight-item.position-center:hover .highlight-details p,
.gold-highlight-item.position-center.active .highlight-details p {
  transform: translateY(0);
  opacity: 1;
}

.gold-highlight-item.position-right .highlight-details p {
  transform: translateX(10px);
  opacity: 0;
  transition-delay: 0.1s;
}

.gold-highlight-item.position-right .highlight-details p:nth-child(2) {
  transition-delay: 0.2s;
}

.gold-highlight-item.position-right .highlight-details p:nth-child(3) {
  transition-delay: 0.3s;
}

.gold-highlight-item.position-right:hover .highlight-details p,
.gold-highlight-item.position-right.active .highlight-details p {
  transform: translateX(0);
  opacity: 1;
}

/* 为卡片添加特殊边框效果，强调展开方向 */
.gold-highlight-item.position-left::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to bottom, transparent, rgba(207, 180, 131, 0.3), transparent);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.gold-highlight-item.position-left:hover::after,
.gold-highlight-item.position-left.active::after {
  opacity: 1;
}

.gold-highlight-item.position-center::after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 50%;
  height: 3px;
  width: 100%;
  background: linear-gradient(to bottom, transparent, rgba(207, 180, 131, 0.3), transparent);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.gold-highlight-item.position-center:hover::after,
.gold-highlight-item.position-center.active::after {
  opacity: 1;
}

.gold-highlight-item.position-right::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to bottom, transparent, rgba(207, 180, 131, 0.3), transparent);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.gold-highlight-item.position-right:hover::after,
.gold-highlight-item.position-right.active::after {
  opacity: 1;
}

.highlight-subtitle {
  color: #CFB483;
  font-size: 1.2rem;
  letter-spacing: 3px;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
}

.subtitle-text {
  color: #666;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  margin-top: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.gold-value-section .section-title {
  margin-bottom: 0.5rem;
}

/* 添加左右渐变蒙版 */
.carousel-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, 
    rgba(255, 255, 255, 1) 0%, 
    rgba(255, 255, 255, 0) 10%, 
    rgba(255, 255, 255, 0) 90%, 
    rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
  z-index: 5;
}

.carousel-item.center-active:hover {
  transform: scale(1.2) translateZ(70px) translateY(-15px);
  box-shadow: 0 20px 40px rgba(9, 23, 56, 0.15);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.carousel-item.center-active:hover .carousel-icon {
  transform: scale(1.15);
  box-shadow: 0 15px 30px rgba(9, 23, 56, 0.3), 0 0 0 15px rgba(207, 180, 131, 0.15);
  background: linear-gradient(45deg, #091738, #4072e5);
}

.carousel-item.center-active:hover .carousel-title {
  transform: translateY(-8px);
  color: #091738;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.carousel-item.center-active:hover .carousel-content {
  transform: translateY(-7px);
  color: #222;
  font-weight: 500;
}

@keyframes kenburns {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2);
  }
}

.hero-title {
  font-size: 4rem;
  letter-spacing: 12px;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: translateY(30px);
  position: relative;
  z-index: 10;
}

/* 预加载下一张幻灯片的背景 */
.hero-slide:not(.active) {
  z-index: 0;
}

/* 调整文字和按钮动画，使之与背景过渡协调 */
.hero-slide.active .hero-title {
  animation: fadeInUp 1.5s 0.3s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-slide.active .hero-subtitle {
  animation: fadeInUp 1.5s 0.6s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-slide.active .hero-description {
  animation: fadeInUp 1.5s 0.9s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

.hero-slide.active .hero-explore-btn {
  animation: fadeInUp 1.5s 1.2s forwards cubic-bezier(0.19, 1, 0.22, 1);
}

/* 添加next类样式，用于预加载下一张幻灯片 */
.hero-slide.next {
  visibility: visible;
  opacity: 0; /* 保持透明 */
  z-index: 1; /* 低于当前活动幻灯片但高于其他幻灯片 */
  transition: none; /* 不需要过渡效果 */
}

.hero-slide.next .hero-slide-bg {
  opacity: 0.1; /* 略微可见，但不会完全显示 */
  transition: opacity 0.5s ease;
}

/* 修复重复的动画定义问题 */
.hero-slide.active .hero-title,
.hero-slide.active .hero-subtitle,
.hero-slide.active .hero-description,
.hero-slide.active .hero-explore-btn {
  animation-name: fadeInUp;
  animation-fill-mode: forwards;
}

.hero-slide.active .hero-title {
  animation-duration: 1.5s;
  animation-delay: 0.3s;
}

.hero-slide.active .hero-subtitle {
  animation-duration: 1.5s;
  animation-delay: 0.6s;
}

.hero-slide.active .hero-description {
  animation-duration: 1.5s;
  animation-delay: 0.9s;
}

.hero-slide.active .hero-explore-btn {
  animation-duration: 1.5s;
  animation-delay: 1.2s;
} 