/* CapitalTransactions.css */
/* 全局样式设置 */
.capital-transactions {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  overflow-x: hidden;
  --gold-primary: #b29366;
  --gold-light: rgba(178, 147, 102, 0.15);
  --gold-medium: rgba(178, 147, 102, 0.5);
  --dark-blue: #0a192f;
  --medium-blue: #172a46;
  --light-blue: #1e355a;
  --text-dark: #2c3e50;
  --text-light: #f8f9fa;
  --text-muted: #7f8c8d;
  --section-padding: 90px 8%;
}

/* 英雄区域样式 */
.capital-transactions .capital-hero-section {
  position: relative;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--dark-blue);
}

.capital-transactions .designer-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.capital-transactions .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, 
    rgba(10, 25, 47, 0.92) 0%, 
    rgba(23, 42, 70, 0.85) 50%, 
    rgba(10, 25, 47, 0.92) 100%);
  z-index: 1;
}

.capital-transactions .pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(45deg, rgba(178, 147, 102, 0.03) 25%, transparent 25%), 
    linear-gradient(-45deg, rgba(178, 147, 102, 0.03) 25%, transparent 25%), 
    linear-gradient(45deg, transparent 75%, rgba(178, 147, 102, 0.03) 75%), 
    linear-gradient(-45deg, transparent 75%, rgba(178, 147, 102, 0.03) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  z-index: 2;
}

.capital-transactions .animated-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.capital-transactions .shape {
  position: absolute;
  backdrop-filter: blur(3px);
  transform-origin: center;
  animation: float 15s infinite ease-in-out;
}

.capital-transactions .shape-1 {
  width: 45vmin;
  height: 45vmin;
  top: -15vmin;
  left: -10vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: linear-gradient(145deg, rgba(10, 25, 47, 0.2), rgba(23, 42, 70, 0.1));
  box-shadow: 0 0 60px rgba(178, 147, 102, 0.05);
  animation-duration: 25s;
}

.capital-transactions .shape-2 {
  width: 35vmin;
  height: 35vmin;
  bottom: -10vmin;
  right: -8vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 50% 50% 70% 30% / 50% 30% 70% 50%;
  background: linear-gradient(145deg, rgba(23, 42, 70, 0.1), rgba(10, 25, 47, 0.2));
  box-shadow: 0 0 40px rgba(178, 147, 102, 0.05);
  animation-delay: -5s;
  animation-duration: 20s;
}

.capital-transactions .shape-3 {
  display: block;
  width: 25vmin;
  height: 25vmin;
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(178, 147, 102, 0.08);
  border-radius: 30% 70% 50% 50% / 50% 50% 50% 50%;
  background: linear-gradient(145deg, rgba(23, 42, 70, 0.05), rgba(10, 25, 47, 0.1));
  opacity: 0.3;
  animation-delay: -10s;
  animation-duration: 18s;
}

/* 动画效果 */
@keyframes float {
  0% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
  25% {
    transform: rotate(3deg) translate(10px, 10px) scale(1.01);
  }
  50% {
    transform: rotate(0deg) translate(20px, 5px) scale(1);
  }
  75% {
    transform: rotate(-3deg) translate(5px, 15px) scale(0.99);
  }
  100% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
}

/* 光效设计 */
.capital-transactions .light-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(178, 147, 102, 0.08) 0%, transparent 35%),
    radial-gradient(circle at 80% 80%, rgba(178, 147, 102, 0.06) 0%, transparent 35%),
    radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.02) 0%, transparent 50%);
  z-index: 2;
  opacity: 1;
}

.capital-transactions .line-decoration {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.5;
}

.capital-transactions .line-decoration::before,
.capital-transactions .line-decoration::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, transparent 0%, var(--gold-primary) 50%, transparent 100%);
  height: 1px;
}

.capital-transactions .line-decoration::before {
  top: 25%;
  left: 0;
  width: 35%;
  transform: rotate(-2deg);
  filter: blur(0.5px);
}

.capital-transactions .line-decoration::after {
  bottom: 30%;
  right: 0;
  width: 35%;
  transform: rotate(2deg);
  filter: blur(0.5px);
}

.capital-transactions .hero-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  overflow: hidden;
}

.capital-transactions .particle {
  position: absolute;
  width: 3px;
  height: 3px;
  background: var(--gold-primary);
  border-radius: 50%;
  opacity: 0.2;
  animation: particles-float 10s infinite linear;
}

.capital-transactions .particle:nth-child(1) {
  top: 20%;
  left: 20%;
  animation-duration: 15s;
  animation-delay: 0s;
}

.capital-transactions .particle:nth-child(2) {
  top: 40%;
  left: 70%;
  animation-duration: 20s;
  animation-delay: -5s;
}

.capital-transactions .particle:nth-child(3) {
  top: 70%;
  left: 30%;
  animation-duration: 18s;
  animation-delay: -8s;
}

.capital-transactions .particle:nth-child(4) {
  top: 15%;
  left: 85%;
  animation-duration: 22s;
  animation-delay: -3s;
}

.capital-transactions .particle:nth-child(5) {
  top: 80%;
  left: 60%;
  animation-duration: 19s;
  animation-delay: -10s;
}

@keyframes particles-float {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    transform: translate(20px, -50px) scale(0);
    opacity: 0;
  }
}

.capital-transactions .capital-hero-content {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
}

.capital-transactions .capital-hero-content h1 {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 6px;
  color: var(--gold-primary);
  margin-bottom: 15px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 15px;
  display: inline-block;
}

.capital-transactions .capital-hero-content h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 1px;
  background: var(--gold-primary);
}

.capital-transactions .capital-hero-content h2 {
  font-size: 3.5rem;
  font-weight: 300;
  color: var(--text-light);
  margin-bottom: 15px;
  letter-spacing: 0;
  line-height: 1.2;
}

.capital-transactions .capital-hero-content h3 {
  font-size: 1.4rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 40px;
  letter-spacing: 0;
}

.capital-transactions .capital-cta-button {
  background-color: transparent;
  color: var(--text-light);
  border: 1px solid var(--gold-primary);
  padding: 15px 32px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.capital-transactions .capital-cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.capital-transactions .capital-cta-button:hover {
  color: var(--dark-blue);
}

.capital-transactions .capital-cta-button:hover::before {
  width: 100%;
}

/* 介绍部分样式 */
.capital-transactions .capital-introduction {
  display: flex;
  flex-wrap: wrap;
  padding: var(--section-padding);
  background-color: #fafafa;
  position: relative;
  overflow: hidden;
}

.capital-transactions .subtle-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 20% 20%, var(--gold-light) 0%, transparent 15%),
    radial-gradient(circle at 80% 80%, var(--gold-light) 0%, transparent 15%);
  opacity: 0.4;
}

.capital-transactions .intro-content {
  flex: 1.15;
  min-width: 300px;
  padding-right: 40px;
  position: relative;
  z-index: 2;
}

.capital-transactions .intro-content h2 {
  font-size: 2.2rem;
  font-weight: 300;
  color: var(--dark-blue);
  margin-bottom: 25px;
  line-height: 1.2;
}

.capital-transactions .intro-content h2::first-letter {
  color: var(--gold-primary);
}

.capital-transactions .gold-separator {
  width: 50px;
  height: 2px;
  background-color: var(--gold-primary);
  margin-bottom: 25px;
  position: relative;
}

.capital-transactions .gold-separator::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 1px;
  background-color: var(--gold-light);
  top: 5px;
  left: 0;
}

.capital-transactions .intro-content p {
  font-size: 1rem;
  line-height: 1.7;
  color: var(--text-muted);
  margin-bottom: 25px;
  font-weight: 300;
}

.capital-transactions .highlighted-text {
  font-size: 1.2rem !important;
  font-style: italic;
  color: var(--dark-blue) !important;
  border-left: 2px solid var(--gold-primary);
  padding: 15px 20px !important;
  margin-top: 30px !important;
  font-weight: 300 !important;
  background: linear-gradient(to right, var(--gold-light), transparent 80%);
  border-radius: 0 4px 4px 0;
}

.capital-transactions .intro-image {
  flex: 0.85;
  min-width: 280px;
  position: relative;
  z-index: 2;
  align-self: center;
  margin-left: 20px;
  max-width: 450px;
}

.capital-transactions .intro-image::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid var(--gold-primary);
  top: -15px;
  left: -15px;
  z-index: 1;
}

.capital-transactions .intro-image img {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
}

/* 能力部分样式 - 全新设计 */
.capital-transactions .capabilities-section {
  padding: var(--section-padding);
  background: linear-gradient(135deg, #f8f9fa 0%, #f2f2f2 100%);
  position: relative;
  overflow: hidden;
}

.capital-transactions .capabilities-bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 10% 20%, var(--gold-light) 0%, transparent 25%),
    radial-gradient(circle at 90% 80%, var(--gold-light) 0%, transparent 25%);
  opacity: 0.6;
  z-index: 1;
}

.capital-transactions .capabilities-section::before {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: linear-gradient(145deg, var(--gold-light), rgba(255,255,255,0.1));
  top: -150px;
  right: -150px;
  z-index: 0;
  opacity: 0.5;
}

.capital-transactions .capabilities-section::after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: linear-gradient(145deg, var(--gold-light), rgba(255,255,255,0.05));
  bottom: -100px;
  left: -100px;
  z-index: 0;
  opacity: 0.4;
}

.capital-transactions .capabilities-section .section-title {
  font-size: 2.5rem;
  font-weight: 300;
  color: var(--dark-blue);
  margin-bottom: 80px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.capital-transactions .capabilities-section .section-title::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: var(--gold-primary);
}

.capital-transactions .capabilities-section .section-title::before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 1px;
  background-color: var(--gold-light);
  bottom: -25px;
}

/* 新的能力展示容器 */
.capital-transactions .capabilities-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
}

/* 能力行样式 */
.capital-transactions .capability-row {
  display: flex;
  align-items: center;
  position: relative;
}

/* 奇数行反向排列 */
.capital-transactions .capability-row:nth-child(odd) {
  flex-direction: row-reverse;
}

/* 能力图标区域 */
.capital-transactions .capability-icon-area {
  flex: 0 0 280px;
  height: 280px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.capital-transactions .capability-icon-outer {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255,255,255,0.8), rgba(255,255,255,0.4));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.capital-transactions .capability-icon-outer::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
    transparent 0deg,
    transparent 60deg,
    var(--gold-primary) 60deg,
    var(--gold-primary) 120deg,
    transparent 120deg,
    transparent 180deg,
    var(--gold-primary) 180deg,
    var(--gold-primary) 240deg,
    transparent 240deg,
    transparent 300deg,
    var(--gold-primary) 300deg,
    var(--gold-primary) 360deg
  );
  opacity: 0.15;
  z-index: -1;
  animation: rotateConic 20s linear infinite;
}

.capital-transactions .capability-icon-inner {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 3;
}

.capital-transactions .capability-icon-inner::after {
  content: '';
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  border: 1px dashed var(--gold-primary);
  opacity: 0.2;
  animation: pulse 2s infinite ease-in-out;
}

.capital-transactions .capability-icon {
  font-size: 2.5rem;
  color: var(--gold-primary);
  z-index: 4;
  transition: all 0.5s ease;
}

.capital-transactions .capability-row:hover .capability-icon {
  transform: scale(1.2);
}

/* 能力内容区域 */
.capital-transactions .capability-content-area {
  flex: 1;
  padding: 20px 40px;
  position: relative;
  z-index: 2;
}

.capital-transactions .capability-content-area::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background: linear-gradient(to bottom, transparent, var(--gold-primary), transparent);
  opacity: 0.3;
}

.capital-transactions .capability-row:nth-child(odd) .capability-content-area::before {
  left: 0;
}

.capital-transactions .capability-row:nth-child(even) .capability-content-area::before {
  right: 0;
}

.capital-transactions .capability-title {
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--dark-blue);
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 15px;
  transition: all 0.3s ease;
}

.capital-transactions .capability-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 40px;
  height: 2px;
  background-color: var(--gold-primary);
  transition: all 0.3s ease;
}

.capital-transactions .capability-row:nth-child(odd) .capability-title::after {
  right: 0;
}

.capital-transactions .capability-row:nth-child(even) .capability-title::after {
  left: 0;
}

.capital-transactions .capability-row:hover .capability-title::after {
  width: 80px;
}

.capital-transactions .capability-text {
  font-size: 1.05rem;
  line-height: 1.8;
  color: var(--text-muted);
  font-weight: 300;
  max-width: 600px;
  position: relative;
  padding: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(5px);
}

.capital-transactions .capability-row:nth-child(odd) .capability-content-area {
  text-align: right;
}

.capital-transactions .capability-row:nth-child(odd) .capability-text {
  margin-left: auto;
  border-right: 2px solid var(--gold-light);
}

.capital-transactions .capability-row:nth-child(even) .capability-text {
  margin-right: auto;
  border-left: 2px solid var(--gold-light);
}

/* 装饰线 */
.capital-transactions .capability-connector {
  position: absolute;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--gold-primary), transparent);
  opacity: 0.2;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  z-index: 1;
}

.capital-transactions .capability-row:nth-child(odd) .capability-connector {
  right: 280px;
}

.capital-transactions .capability-row:nth-child(even) .capability-connector {
  left: 280px;
}

/* 序号装饰 */
.capital-transactions .capability-number {
  position: absolute;
  font-size: 10rem;
  font-weight: 700;
  color: rgba(10, 25, 47, 0.03);
  z-index: 0;
  line-height: 1;
}

.capital-transactions .capability-row:nth-child(odd) .capability-number {
  left: 40px;
  top: -40px;
}

.capital-transactions .capability-row:nth-child(even) .capability-number {
  right: 40px;
  top: -40px;
}

/* 动画定义 */
@keyframes rotateConic {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.4;
  }
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .capital-transactions .capability-icon-area {
    flex: 0 0 220px;
    height: 220px;
  }
  
  .capital-transactions .capability-icon-outer {
    width: 150px;
    height: 150px;
  }
  
  .capital-transactions .capability-icon-inner {
    width: 100px;
    height: 100px;
  }
  
  .capital-transactions .capability-title {
    font-size: 1.6rem;
  }
  
  .capital-transactions .capability-number {
    font-size: 8rem;
  }
}

@media (max-width: 992px) {
  .capital-transactions .capabilities-section .section-title {
    font-size: 2.2rem;
    margin-bottom: 60px;
  }
  
  .capital-transactions .capability-row,
  .capital-transactions .capability-row:nth-child(odd) {
    flex-direction: column;
    gap: 30px;
  }
  
  .capital-transactions .capability-icon-area {
    flex: 0 0 auto;
    height: auto;
  }
  
  .capital-transactions .capability-content-area,
  .capital-transactions .capability-row:nth-child(odd) .capability-content-area {
    text-align: center;
    padding: 20px;
  }
  
  .capital-transactions .capability-content-area::before {
    display: none;
  }
  
  .capital-transactions .capability-title::after,
  .capital-transactions .capability-row:nth-child(odd) .capability-title::after,
  .capital-transactions .capability-row:nth-child(even) .capability-title::after {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
  
  .capital-transactions .capability-text,
  .capital-transactions .capability-row:nth-child(odd) .capability-text,
  .capital-transactions .capability-row:nth-child(even) .capability-text {
    margin: 0 auto;
    border: none;
    border-bottom: 2px solid var(--gold-light);
  }
  
  .capital-transactions .capability-connector {
    display: none;
  }
  
  .capital-transactions .capability-number {
    opacity: 0.02;
    font-size: 7rem;
  }
  
  .capital-transactions .capability-row:nth-child(odd) .capability-number,
  .capital-transactions .capability-row:nth-child(even) .capability-number {
    left: 50%;
    right: auto;
    top: auto;
    bottom: -30px;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .capital-transactions .capabilities-section .section-title {
    font-size: 1.8rem;
  }
  
  .capital-transactions .capability-icon-outer {
    width: 130px;
    height: 130px;
  }
  
  .capital-transactions .capability-icon-inner {
    width: 90px;
    height: 90px;
  }
  
  .capital-transactions .capability-icon {
    font-size: 2rem;
  }
  
  .capital-transactions .capability-title {
    font-size: 1.4rem;
  }
  
  .capital-transactions .capability-text {
    font-size: 1rem;
    padding: 15px;
  }
  
  .capital-transactions .capability-number {
    font-size: 6rem;
  }
}

@media (max-width: 576px) {
  .capital-transactions .capabilities-section {
    padding: 70px 5%;
  }
  
  .capital-transactions .capabilities-section .section-title {
    font-size: 1.6rem;
    margin-bottom: 50px;
  }
  
  .capital-transactions .capability-icon-outer {
    width: 110px;
    height: 110px;
  }
  
  .capital-transactions .capability-icon-inner {
    width: 80px;
    height: 80px;
  }
  
  .capital-transactions .capability-icon {
    font-size: 1.8rem;
  }
  
  .capital-transactions .capability-title {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  
  .capital-transactions .capability-text {
    font-size: 0.95rem;
    padding: 12px;
  }
  
  .capital-transactions .capability-number {
    font-size: 5rem;
    opacity: 0.01;
  }
}

/* 视觉分隔部分样式 */
.capital-transactions .visual-divider {
  height: 400px;
  position: relative;
  overflow: hidden;
}

.capital-transactions .parallax-image {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.capital-transactions .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(10, 25, 47, 0.9) 0%, rgba(10, 25, 47, 0.8) 100%);
}

.capital-transactions .content-container {
  position: relative;
  z-index: 10;
  width: 90%;
  max-width: 800px;
  text-align: center;
}

.capital-transactions .divider-content {
  padding: 30px;
  background: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(5px);
  border-left: 3px solid var(--gold-primary);
  position: relative;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transform: translateZ(0);
}

.capital-transactions .divider-content::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px solid rgba(178, 147, 102, 0.2);
  pointer-events: none;
}

.capital-transactions .divider-content h2 {
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--gold-primary);
  margin-bottom: 25px;
  line-height: 1.4;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.capital-transactions .divider-content .gold-separator {
  margin: 0 auto 25px auto;
  background-color: var(--gold-primary);
  opacity: 0.7;
}

.capital-transactions .divider-content p {
  font-size: 1rem;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 300;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* 服务部分样式 */
.capital-transactions .services-section {
  padding: var(--section-padding);
  background-color: #fafafa;
  position: relative;
  overflow: hidden;
}

.capital-transactions .services-bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(var(--gold-light) 1px, transparent 1px),
    radial-gradient(var(--gold-light) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: 0 0, 25px 25px;
  opacity: 0.1;
  z-index: 1;
}

.capital-transactions .services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 50px;
  position: relative;
  z-index: 2;
}

.capital-transactions .service-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
}

.capital-transactions .service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.capital-transactions .service-image {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.capital-transactions .service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.capital-transactions .service-card:hover .service-image img {
  transform: scale(1.1);
}

.capital-transactions .service-content {
  padding: 25px;
  position: relative;
}

.capital-transactions .service-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--dark-blue);
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 15px;
}

.capital-transactions .service-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--gold-primary);
}

.capital-transactions .service-text {
  font-size: 0.95rem;
  line-height: 1.7;
  color: var(--text-muted);
  font-weight: 300;
  margin-bottom: 20px;
}

.capital-transactions .service-link {
  font-size: 0.9rem;
  color: var(--gold-primary);
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.capital-transactions .service-link i {
  margin-left: 5px;
  transition: all 0.3s ease;
}

.capital-transactions .service-card:hover .service-link {
  color: var(--dark-blue);
}

.capital-transactions .service-card:hover .service-link i {
  transform: translateX(5px);
}

/* 联系我们部分样式 */
.capital-transactions .contact-section {
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-blue);
  overflow: hidden;
}

.capital-transactions .contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(10, 25, 47, 0.9) 0%, rgba(10, 25, 47, 0.95) 100%);
  z-index: 1;
}

.capital-transactions .contact-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(45deg, var(--medium-blue) 25%, transparent 25%),
    linear-gradient(-45deg, var(--medium-blue) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--medium-blue) 75%),
    linear-gradient(-45deg, transparent 75%, var(--medium-blue) 75%);
  background-size: 20px 20px;
  opacity: 0.02;
  z-index: 2;
}

.capital-transactions .contact-content {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0 20px;
  max-width: 700px;
}

.capital-transactions .contact-content h2 {
  font-size: 2rem;
  font-weight: 300;
  color: var(--text-light);
  margin-bottom: 25px;
  letter-spacing: 0;
}

.capital-transactions .contact-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
  font-weight: 300;
}

.capital-transactions .contact-button {
  background-color: transparent;
  color: var(--text-light);
  border: 1px solid var(--gold-primary);
  padding: 14px 32px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.capital-transactions .contact-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.capital-transactions .contact-button:hover {
  color: var(--dark-blue);
}

.capital-transactions .contact-button:hover::before {
  width: 100%;
}

/* 创新部分：图像展示墙 - 全新设计 */
.capital-transactions .image-showcase {
  padding: var(--section-padding);
  background: linear-gradient(135deg, #fbfbfb 0%, #f5f5f5 100%);
  position: relative;
  overflow: hidden;
}

.capital-transactions .image-showcase::before {
  content: '';
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--gold-light) 0%, transparent 70%);
  top: -200px;
  left: -200px;
  opacity: 0.7;
  z-index: 1;
}

.capital-transactions .image-showcase::after {
  content: '';
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: radial-gradient(circle, var(--gold-light) 0%, transparent 70%);
  bottom: -150px;
  right: -150px;
  opacity: 0.5;
  z-index: 1;
}

.capital-transactions .image-showcase .section-title {
  position: relative;
  z-index: 2;
  margin-bottom: 60px;
}

.capital-transactions .showcase-container {
  position: relative;
  z-index: 2;
  max-width: 1400px;
  margin: 0 auto;
}

.capital-transactions .showcase-layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(8, 70px);
  gap: 15px;
  position: relative;
}

.capital-transactions .showcase-decor-line {
  position: absolute;
  background: linear-gradient(90deg, transparent, var(--gold-primary), transparent);
  height: 1px;
  width: 50%;
  opacity: 0.3;
  z-index: 1;
}

.capital-transactions .showcase-decor-line:nth-child(1) {
  top: 20%;
  left: 0;
  width: 30%;
}

.capital-transactions .showcase-decor-line:nth-child(2) {
  bottom: 30%;
  right: 0;
  width: 30%;
}

.capital-transactions .showcase-decor-circle {
  position: absolute;
  border-radius: 50%;
  border: 1px dashed var(--gold-primary);
  opacity: 0.15;
  z-index: 1;
}

.capital-transactions .showcase-decor-circle:nth-child(3) {
  width: 200px;
  height: 200px;
  top: 20%;
  right: 10%;
}

.capital-transactions .showcase-decor-circle:nth-child(4) {
  width: 300px;
  height: 300px;
  bottom: 10%;
  left: 5%;
}

.capital-transactions .showcase-item {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform-style: preserve-3d;
  perspective: 1000px;
}

.capital-transactions .showcase-item::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(10, 25, 47, 0.3), transparent 50%);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.capital-transactions .showcase-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.capital-transactions .showcase-item:hover::before {
  opacity: 1;
}

.capital-transactions .showcase-item.item-1 {
  grid-column: 1 / 7;
  grid-row: 1 / 6;
  z-index: 3;
}

.capital-transactions .showcase-item.item-2 {
  grid-column: 7 / 10;
  grid-row: 1 / 5;
  z-index: 2;
}

.capital-transactions .showcase-item.item-3 {
  grid-column: 10 / 13;
  grid-row: 1 / 4;
  z-index: 2;
}

.capital-transactions .showcase-item.item-4 {
  grid-column: 7 / 13;
  grid-row: 5 / 9;
  z-index: 3;
}

.capital-transactions .showcase-item.item-5 {
  grid-column: 1 / 7;
  grid-row: 6 / 9;
  z-index: 2;
}

.capital-transactions .showcase-image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.capital-transactions .showcase-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s cubic-bezier(0.33, 0.1, 0.5, 1);
  transform-origin: center;
}

.capital-transactions .showcase-item:hover .showcase-image {
  transform: scale(1.05);
}

.capital-transactions .showcase-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  z-index: 5;
  transform: translateZ(20px);
}

.capital-transactions .showcase-label {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--gold-primary);
  background: rgba(255, 255, 255, 0.95);
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 20px;
  letter-spacing: 1px;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.5s ease;
}

.capital-transactions .showcase-item:hover .showcase-label {
  transform: translateY(0);
  opacity: 1;
}

.capital-transactions .showcase-title {
  color: white;
  font-size: 1.4rem;
  font-weight: 300;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.5s ease;
  transition-delay: 0.1s;
}

.capital-transactions .showcase-item:hover .showcase-title {
  transform: translateY(0);
  opacity: 1;
}

.capital-transactions .showcase-separator {
  display: block;
  width: 0;
  height: 2px;
  background-color: var(--gold-primary);
  margin: 15px 0;
  transition: width 0.5s ease;
  transition-delay: 0.2s;
}

.capital-transactions .showcase-item:hover .showcase-separator {
  width: 40px;
}

.capital-transactions .showcase-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
  max-width: 80%;
  margin: 0;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.5s ease;
  transition-delay: 0.2s;
}

.capital-transactions .showcase-item:hover .showcase-description {
  transform: translateY(0);
  opacity: 1;
}

.capital-transactions .showcase-view {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 5;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.capital-transactions .showcase-item:hover .showcase-view {
  transform: translateY(0);
  opacity: 1;
}

.capital-transactions .showcase-view:hover {
  background-color: var(--gold-primary);
  color: var(--dark-blue);
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .capital-transactions .showcase-layout {
    grid-template-rows: repeat(8, 60px);
  }
  
  .capital-transactions .showcase-title {
    font-size: 1.2rem;
  }
  
  .capital-transactions .showcase-description {
    font-size: 0.85rem;
  }
}

@media (max-width: 992px) {
  .capital-transactions .showcase-layout {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, 80px);
    gap: 15px;
  }
  
  .capital-transactions .showcase-item.item-1 {
    grid-column: 1 / 7;
    grid-row: 1 / 5;
  }
  
  .capital-transactions .showcase-item.item-2 {
    grid-column: 1 / 4;
    grid-row: 5 / 9;
  }
  
  .capital-transactions .showcase-item.item-3 {
    grid-column: 4 / 7;
    grid-row: 5 / 9;
  }
  
  .capital-transactions .showcase-item.item-4 {
    grid-column: 1 / 4;
    grid-row: 9 / 13;
  }
  
  .capital-transactions .showcase-item.item-5 {
    grid-column: 4 / 7;
    grid-row: 9 / 13;
  }
}

@media (max-width: 768px) {
  .capital-transactions .showcase-layout {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 300px);
    gap: 20px;
  }
  
  .capital-transactions .showcase-item.item-1,
  .capital-transactions .showcase-item.item-2,
  .capital-transactions .showcase-item.item-3,
  .capital-transactions .showcase-item.item-4,
  .capital-transactions .showcase-item.item-5 {
    grid-column: 1;
    grid-row: auto;
  }
  
  .capital-transactions .showcase-content {
    transform: translateZ(0);
    padding: 20px;
  }
  
  .capital-transactions .showcase-title {
    font-size: 1.3rem;
    transform: translateY(0);
    opacity: 1;
  }
  
  .capital-transactions .showcase-label {
    transform: translateY(0);
    opacity: 1;
  }
  
  .capital-transactions .showcase-separator {
    width: 40px;
  }
  
  .capital-transactions .showcase-description {
    transform: translateY(0);
    opacity: 1;
    font-size: 0.9rem;
  }
  
  .capital-transactions .showcase-view {
    transform: translateY(0);
    opacity: 1;
  }
  
  .capital-transactions .showcase-item::before {
    opacity: 1;
    background: linear-gradient(to top, rgba(10, 25, 47, 0.5), transparent 70%);
  }
}

@media (max-width: 576px) {
  .capital-transactions .showcase-layout {
    grid-template-rows: repeat(5, 250px);
    gap: 15px;
  }
  
  .capital-transactions .showcase-content {
    padding: 15px;
  }
  
  .capital-transactions .showcase-title {
    font-size: 1.1rem;
  }
  
  .capital-transactions .showcase-label {
    font-size: 0.65rem;
    padding: 4px 8px;
    margin-bottom: 10px;
  }
  
  .capital-transactions .showcase-description {
    font-size: 0.8rem;
    max-width: 100%;
  }
  
  .capital-transactions .showcase-view {
    width: 40px;
    height: 40px;
    font-size: 1rem;
    top: 15px;
    right: 15px;
  }
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .capital-transactions .capital-hero-content h2 {
    font-size: 3rem;
  }
  
  .capital-transactions .intro-content h2,
  .capital-transactions .section-title,
  .capital-transactions .contact-content h2 {
    font-size: 2rem;
  }
  
  .capital-transactions .capability-title,
  .capital-transactions .service-title {
    font-size: 1.2rem;
  }
  
  .capital-transactions .image-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 200px;
  }
}

@media (max-width: 992px) {
  .capital-transactions .capital-hero-content h1 {
    font-size: 1rem;
  }
  
  .capital-transactions .capital-hero-content h2 {
    font-size: 2.5rem;
  }
  
  .capital-transactions .capital-hero-content h3 {
    font-size: 1.2rem;
  }
  
  .capital-transactions .intro-content {
    padding-right: 0;
    margin-bottom: 40px;
  }
  
  .capital-transactions .intro-content h2 {
    font-size: 1.8rem;
  }
  
  .capital-transactions .visual-divider {
    height: 350px;
  }
  
  .capital-transactions .divider-content h2 {
    font-size: 1.6rem;
  }
  
  .capital-transactions .showcase-item.large {
    grid-row: span 1;
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .capital-transactions .capital-hero-section {
    height: 80vh;
  }
  
  .capital-transactions .capital-hero-content h1 {
    font-size: 0.9rem;
    letter-spacing: 4px;
  }
  
  .capital-transactions .capital-hero-content h2 {
    font-size: 2rem;
  }
  
  .capital-transactions .capital-hero-content h3 {
    font-size: 1rem;
  }
  
  .capital-transactions .intro-content h2, 
  .capital-transactions .section-title, 
  .capital-transactions .contact-content h2 {
    font-size: 1.6rem;
  }
  
  .capital-transactions .capability-icon {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
  }
  
  .capital-transactions .intro-image::before {
    display: none;
  }
  
  .capital-transactions .image-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 150px;
    gap: 10px;
  }
}

@media (max-width: 576px) {
  .capital-transactions .capital-hero-content h1 {
    font-size: 0.8rem;
    letter-spacing: 3px;
  }
  
  .capital-transactions .capital-hero-content h2 {
    font-size: 1.8rem;
  }
  
  .capital-transactions .capital-hero-content h3 {
    font-size: 1rem;
  }
  
  .capital-transactions .capital-cta-button, 
  .capital-transactions .contact-button {
    padding: 12px 25px;
    font-size: 0.8rem;
  }
  
  .capital-transactions .intro-content h2, 
  .capital-transactions .section-title, 
  .capital-transactions .contact-content h2 {
    font-size: 1.5rem;
  }
  
  .capital-transactions .highlighted-text {
    font-size: 1rem !important;
    padding: 12px 15px !important;
  }
  
  .capital-transactions .capability-title,
  .capital-transactions .service-title {
    font-size: 1.1rem;
  }
  
  .capital-transactions .capability-icon {
    width: 45px;
    height: 45px;
    font-size: 1.3rem;
    margin: 20px auto 10px;
  }
  
  .capital-transactions .contact-content p {
    font-size: 0.95rem;
  }
  
  .capital-transactions .showcase-title {
    font-size: 1rem;
  }
} 