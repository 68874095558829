.team-page {
  min-height: 100vh;
  background-color: #fff;
}

.team-header {
  padding: 180px 20px 120px;
  background: linear-gradient(45deg, rgba(11, 28, 61, 0.98), rgba(0, 0, 0, 0.95));
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.team-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, 
    rgba(207, 180, 131, 0.05) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(207, 180, 131, 0.05) 50%, 
    rgba(207, 180, 131, 0.05) 75%, 
    transparent 75%, 
    transparent
  );
  background-size: 60px 60px;
  opacity: 0;
  animation: backgroundFloat 30s linear infinite, fadeIn 1s ease-out forwards 1.2s;
}

.team-header h1 {
  font-size: 4.5rem;
  margin-bottom: 1.5rem;
  font-weight: 200;
  letter-spacing: 8px;
  line-height: 1.2;
  background: linear-gradient(45deg, #fff, #CFB483);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transform: translateY(30px) scale(0.95);
  animation: titleReveal 1.2s cubic-bezier(0.2, 0, 0.2, 1) forwards;
}

.header-content {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.header-subtitle {
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: 300;
  letter-spacing: 4px;
  color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  transform: translateY(20px);
  animation: subtitleReveal 0.8s cubic-bezier(0.2, 0, 0.2, 1) forwards 0.6s;
  position: relative;
}

.header-subtitle::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 0;
  height: 1px;
  background: #CFB483;
  transform: translateX(-50%);
  animation: lineExpand 0.6s ease-out forwards 1.4s;
}

.header-description {
  font-size: 1.3rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 300;
  position: relative;
  padding: 0 40px;
  opacity: 0;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  animation: textReveal 0.8s cubic-bezier(0.2, 0, 0.2, 1) forwards 1s;
}

.header-description::before,
.header-description::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 60px;
  background: linear-gradient(to bottom, #CFB483, transparent);
  transform: scaleY(0);
  animation: lineGrow 0.6s ease-out forwards 1.6s;
}

.header-description::before {
  left: 0;
  top: 0;
  transform-origin: top;
}

.header-description::after {
  right: 0;
  bottom: 0;
  transform-origin: bottom;
}

.team-vision {
  padding: 80px 20px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  background: #f8f9fa;
}

.team-vision h2 {
  font-size: 2.5rem;
  color: #001233;
  margin-bottom: 2rem;
  font-weight: 700;
}

.team-vision p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

.team-gallery {
  padding: 120px 20px;
  background-color: #fff;
  position: relative;
  overflow: visible;
  margin-bottom: 80px;
}

.team-gallery::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(207, 180, 131, 0.3), transparent);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 0;
}

.team-member {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  aspect-ratio: 1/1;
  background: #fff;
  will-change: transform;
}

.team-member:first-child {
  grid-column: 1;
  aspect-ratio: 1/1;
}

.team-member:nth-child(4) {
  grid-column: 1;
  aspect-ratio: 1/1;
}

.member-image {
  position: relative;
  overflow: hidden;
  height: 100%;
  will-change: transform;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

.team-member:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
}

.team-member:hover .member-image img {
  transform: scale(1.1);
}

.member-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, 
    rgba(11, 28, 61, 0.95),
    rgba(11, 28, 61, 0.8) 60%,
    transparent 100%
  );
  padding: 40px 40px;
  color: #fff;
  transform: translateY(68%);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.team-member:hover .member-overlay {
  transform: translateY(0);
}

.member-overlay h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-weight: 200;
  letter-spacing: 3px;
  color: #fff;
  position: relative;
  padding-bottom: 15px;
}

.member-overlay h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: #CFB483;
  transform: scaleX(0);
  transition: transform 0.4s ease;
  transform-origin: left;
}

.team-member:hover .member-overlay h3::after {
  transform: scaleX(1);
}

.member-overlay p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
  letter-spacing: 1px;
}

.team-expertise,
.team-values {
  padding: 120px 20px;
  background: #fff;
  position: relative;
  overflow: visible;
}

.team-expertise {
  margin-top: 60px;
}

.team-values {
  background: #f8f9fa;
}

.expertise-content,
.values-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  position: relative;
}

.text-section {
  padding-right: 20px;
}

.text-section h2 {
  font-size: 2.5rem;
  color: #001233;
  margin-bottom: 2rem;
  position: relative;
}

.text-section h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 2px;
  background: #CFB483;
}

.text-section p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;
}

.image-section img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.floating-image {
  animation: float 8s ease-in-out infinite;
  will-change: transform;
}

.contact-section {
  padding: 180px 20px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.contact-section::before {
  content: none;
}

.contact-content {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.contact-content h2 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  font-weight: 200;
  letter-spacing: 6px;
  background: linear-gradient(45deg, #fff, #CFB483);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-content p {
  font-size: 1.6rem;
  margin-bottom: 3rem;
  font-weight: 300;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.9);
}

.contact-button {
  display: inline-block;
  padding: 1.5rem 4rem;
  background: transparent;
  border: 2px solid #CFB483;
  color: #CFB483;
  font-size: 1.2rem;
  text-decoration: none;
  letter-spacing: 3px;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

.contact-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #CFB483;
  transform: translateX(-100%);
  transition: transform 0.4s ease;
  z-index: -1;
}

.contact-button:hover {
  color: #0b1c3d;
}

.contact-button:hover::before {
  transform: translateX(0);
}

@keyframes titleReveal {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes subtitleReveal {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textReveal {
  0% {
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  100% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes lineGrow {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes lineExpand {
  0% {
    width: 0;
  }
  100% {
    width: 100px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.1;
  }
}

@keyframes backgroundFloat {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .team-header h1 {
    font-size: 3.8rem;
  }
  
  .header-subtitle {
    font-size: 1.8rem;
  }
  
  .text-section h2 {
    font-size: 2rem;
  }
  
  .contact-content h2 {
    font-size: 2.8rem;
  }
  
  .contact-content p {
    font-size: 1.3rem;
  }
  
  .gallery-grid {
    gap: 40px;
    padding: 30px 0;
  }
  
  .member-overlay {
    padding: 40px 30px;
  }
  
  .member-overlay h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 992px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    padding-bottom: 40px;
  }
  
  .team-member:first-child,
  .team-member:nth-child(4) {
    grid-column: span 1;
  }
  
  .team-expertise,
  .team-values {
    padding: 100px 20px;
  }
  
  .expertise-content,
  .values-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  
  .text-section {
    padding-right: 0;
    order: 1;
  }
  
  .image-section {
    order: 2;
  }
}

@media (max-width: 768px) {
  .team-header {
    padding: 140px 20px 100px;
  }

  .team-header h1 {
    font-size: 3rem;
    letter-spacing: 6px;
  }
  
  .header-subtitle {
    font-size: 1.5rem;
    letter-spacing: 3px;
  }
  
  .header-description {
    font-size: 1.1rem;
    padding: 0 20px;
  }
  
  .header-description::before,
  .header-description::after {
    height: 40px;
  }
  
  .team-vision h2 {
    font-size: 2rem;
  }
  
  .text-section h2 {
    font-size: 2rem;
  }
  
  .contact-content h2 {
    font-size: 2.8rem;
  }
  
  .contact-content p {
    font-size: 1.3rem;
  }
  
  .gallery-grid {
    grid-template-columns: 1fr;
    gap: 25px;
    padding-bottom: 20px;
  }
  
  .team-member {
    aspect-ratio: 16/9;
  }
  
  .member-overlay {
    transform: translateY(62%);
    padding: 30px 25px;
  }
  
  .member-overlay h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  
  .member-overlay p {
    font-size: 1.1rem;
  }
  
  .team-member:hover {
    transform: none;
  }
  
  .team-member:hover .member-image img {
    transform: none;
  }
  
  .team-gallery {
    padding: 80px 20px;
    margin-bottom: 40px;
  }
  
  .team-expertise,
  .team-values {
    padding: 80px 20px;
  }
}

/* 新的联系部分样式 */
.contact-section-wrapper {
  position: relative;
  padding: 180px 0;
  text-align: center;
  overflow: hidden;
}

.contact-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.contact-section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(11, 28, 61, 0.95), rgba(0, 0, 0, 0.85));
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-content {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  color: #fff;
  padding: 0 20px;
} 