.site-footer {
  background-color: #0b1c3d;
  color: #fff;
  position: relative;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.05);
}

.footer-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4rem 0 2rem;
  gap: 1.5rem;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

.brand-column {
  flex: 1.5;
}

.footer-title {
  color: #CFB483;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 2px;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.footer-title:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 40px;
  height: 2px;
  background: linear-gradient(90deg, #CFB483, transparent);
}

.brand-description {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.social-icons {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(207, 180, 131, 0.1);
  color: #CFB483;
  transition: all 0.3s ease;
}

.social-icon:hover {
  background-color: #CFB483;
  color: #0b1c3d;
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.column-title {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  position: relative;
  display: inline-block;
}

.column-title:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 30px;
  height: 2px;
  background: #CFB483;
}

.contact-list, 
.quick-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-list li, 
.quick-links li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.contact-list li i {
  color: #CFB483;
  margin-right: 12px;
  min-width: 20px;
}

.quick-links a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding-left: 15px;
}

.quick-links a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #CFB483;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.quick-links a:hover {
  color: #CFB483;
  transform: translateX(5px);
}

.quick-links a:hover:before {
  opacity: 1;
}

.newsletter-text {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.newsletter-input {
  padding: 12px 15px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  outline: none;
  transition: all 0.3s ease;
}

.newsletter-input:focus {
  border-color: #CFB483;
  box-shadow: 0 0 0 2px rgba(207, 180, 131, 0.2);
}

.newsletter-button {
  padding: 12px 20px;
  background: linear-gradient(45deg, #CFB483, #E0CF83);
  border: none;
  border-radius: 4px;
  color: #0b1c3d;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
}

.newsletter-button:hover {
  background: linear-gradient(45deg, #E0CF83, #CFB483);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.footer-divider {
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(207, 180, 131, 0.3), transparent);
  margin: 0 auto;
  width: 100%;
}

.footer-bottom {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
}

.footer-bottom .footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.copyright-text {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

.copyright-brand {
  color: #CFB483;
  font-weight: 500;
  letter-spacing: 1px;
}

.footer-legal {
  display: flex;
  align-items: center;
}

.footer-legal a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.9rem;
}

.footer-legal a:hover {
  color: #CFB483;
}

.divider-dot {
  margin: 0 10px;
  color: rgba(255, 255, 255, 0.3);
}

.back-to-top {
  position: fixed;
  right: 25px;
  bottom: 25px;
  background: linear-gradient(135deg, #CFB483, #E0CF83);
  color: #0b1c3d;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0.9;
  z-index: 100;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.back-to-top:hover {
  opacity: 1;
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
}

@media (max-width: 1024px) {
  .footer-main {
    gap: 1rem;
    justify-content: space-around;
  }
  .footer-column {
    min-width: 180px;
    padding: 0 0.5rem;
  }
  .brand-column {
    flex: 1 1 100%;
  }
}

@media (max-width: 768px) {
  .footer-main {
    flex-direction: column;
    gap: 2.5rem;
  }
  
  .footer-column {
    flex: 0 0 100%;
    padding: 0;
  }
  
  .footer-bottom .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }
  
  .back-to-top {
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
  }
} 