.navbar {
  background-color: transparent;
  padding: 1.2rem 0;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.navbar.scrolled {
  background-color: rgba(0, 18, 51, 0.98);
  padding: 0.8rem 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 45px;
  margin-right: 1rem;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #CFB483, #E0CF83);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-link:hover {
  color: #CFB483;
}

.nav-link:hover::after {
  width: 100%;
}

.dropdown-arrow {
  font-size: 0.7rem;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.nav-item:hover .dropdown-arrow {
  transform: rotate(180deg);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: rgba(0, 18, 51, 0.98);
  min-width: 200px;
  padding: 1rem 0;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(207, 180, 131, 0.1);
}

.dropdown-content.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.dropdown-content::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 18, 51, 0.98);
}

.dropdown-content a {
  color: #fff;
  text-decoration: none;
  padding: 0.7rem 1.5rem;
  display: block;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.dropdown-content a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, #CFB483, #E0CF83);
  transform: scaleY(0);
  transition: transform 0.3s ease;
}

.dropdown-content a:hover {
  color: #CFB483;
  padding-left: 2rem;
}

.dropdown-content a:hover::before {
  transform: scaleY(1);
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .container {
    padding: 0 1.5rem;
  }
  
  .nav-links {
    gap: 1rem;
  }
  
  .nav-link {
    font-size: 0.9rem;
    padding: 0.5rem 0.6rem;
  }
}

@media (max-width: 992px) {
  .navbar-menu {
    display: none;
  }
  
  .container {
    padding: 0 1rem;
  }
  
  .logo {
    height: 40px;
  }
} 