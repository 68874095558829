/* AssetManagement.css */
/* 全局样式设置 */
.asset-management {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  overflow-x: hidden;
  --gold-primary: #b29366;
  --gold-light: rgba(178, 147, 102, 0.15);
  --gold-medium: rgba(178, 147, 102, 0.5);
  --dark-blue: #0a192f;
  --medium-blue: #172a46;
  --light-blue: #1e355a;
  --text-dark: #2c3e50;
  --text-light: #f8f9fa;
  --text-muted: #7f8c8d;
  --section-padding: 90px 8%;
}

/* 英雄区域样式 */
.asset-management .asset-hero-section {
  position: relative;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--dark-blue);
}

.asset-management .designer-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.asset-management .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, 
    rgba(10, 25, 47, 0.95) 0%, 
    rgba(23, 42, 70, 0.85) 50%, 
    rgba(10, 25, 47, 0.95) 100%);
  z-index: 1;
}

.asset-management .pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    repeating-linear-gradient(45deg, rgba(178, 147, 102, 0.03) 0, rgba(178, 147, 102, 0.03) 1px, transparent 1px, transparent 50px),
    repeating-linear-gradient(135deg, rgba(178, 147, 102, 0.03) 0, rgba(178, 147, 102, 0.03) 1px, transparent 1px, transparent 50px);
  background-size: 50px 50px;
  background-position: center center;
  z-index: 2;
}

.asset-management .animated-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.asset-management .shape {
  position: absolute;
  backdrop-filter: blur(3px);
  transform-origin: center;
  animation: float 15s infinite ease-in-out;
}

.asset-management .shape-1 {
  width: 45vmin;
  height: 45vmin;
  top: -15vmin;
  left: -10vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  background: linear-gradient(145deg, rgba(10, 25, 47, 0.2), rgba(23, 42, 70, 0.1));
  box-shadow: 0 0 60px rgba(178, 147, 102, 0.05);
  animation-duration: 25s;
}

.asset-management .shape-2 {
  width: 35vmin;
  height: 35vmin;
  bottom: -10vmin;
  right: -8vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 59% 41% 38% 62% / 42% 60% 40% 58%;
  background: linear-gradient(145deg, rgba(23, 42, 70, 0.1), rgba(10, 25, 47, 0.2));
  box-shadow: 0 0 40px rgba(178, 147, 102, 0.05);
  animation-delay: -5s;
  animation-duration: 20s;
}

.asset-management .shape-3 {
  display: block;
  width: 25vmin;
  height: 25vmin;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(178, 147, 102, 0.08);
  border-radius: 46% 54% 43% 57% / 51% 39% 61% 49%;
  background: linear-gradient(145deg, rgba(23, 42, 70, 0.05), rgba(10, 25, 47, 0.1));
  opacity: 0.3;
  animation-delay: -10s;
  animation-duration: 18s;
}

/* 动画效果 */
@keyframes float {
  0% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
  25% {
    transform: rotate(3deg) translate(10px, 10px) scale(1.01);
  }
  50% {
    transform: rotate(0deg) translate(20px, 5px) scale(1);
  }
  75% {
    transform: rotate(-3deg) translate(5px, 15px) scale(0.99);
  }
  100% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
}

/* 光效设计 */
.asset-management .light-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(178, 147, 102, 0.08) 0%, transparent 35%),
    radial-gradient(circle at 80% 80%, rgba(178, 147, 102, 0.06) 0%, transparent 35%),
    radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.02) 0%, transparent 50%);
  z-index: 2;
  opacity: 1;
}

.asset-management .line-decoration {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.5;
}

.asset-management .line-decoration::before,
.asset-management .line-decoration::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, transparent 0%, var(--gold-primary) 50%, transparent 100%);
  height: 1px;
}

.asset-management .line-decoration::before {
  top: 25%;
  left: 0;
  width: 25%;
  transform: rotate(-5deg);
  filter: blur(0.5px);
}

.asset-management .line-decoration::after {
  bottom: 30%;
  right: 0;
  width: 25%;
  transform: rotate(5deg);
  filter: blur(0.5px);
}

.asset-management .hero-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  overflow: hidden;
}

.asset-management .particle {
  position: absolute;
  width: 3px;
  height: 3px;
  background: var(--gold-primary);
  border-radius: 50%;
  opacity: 0.2;
  animation: particles-float 10s infinite linear;
}

.asset-management .particle:nth-child(1) {
  top: 20%;
  left: 20%;
  animation-duration: 15s;
  animation-delay: 0s;
}

.asset-management .particle:nth-child(2) {
  top: 40%;
  left: 70%;
  animation-duration: 20s;
  animation-delay: -5s;
}

.asset-management .particle:nth-child(3) {
  top: 70%;
  left: 30%;
  animation-duration: 18s;
  animation-delay: -8s;
}

.asset-management .particle:nth-child(4) {
  top: 15%;
  left: 85%;
  animation-duration: 22s;
  animation-delay: -3s;
}

.asset-management .particle:nth-child(5) {
  top: 80%;
  left: 60%;
  animation-duration: 19s;
  animation-delay: -10s;
}

@keyframes particles-float {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    transform: translate(20px, -50px) scale(0);
    opacity: 0;
  }
}

.asset-management .asset-hero-content {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
}

.asset-management .asset-hero-content h1 {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 6px;
  color: var(--gold-primary);
  margin-bottom: 15px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 15px;
  display: inline-block;
}

.asset-management .asset-hero-content h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 1px;
  background: var(--gold-primary);
}

.asset-management .asset-hero-content h2 {
  font-size: 3.5rem;
  font-weight: 300;
  color: var(--text-light);
  margin-bottom: 15px;
  letter-spacing: 0;
  line-height: 1.2;
}

.asset-management .asset-hero-content h3 {
  font-size: 1.4rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 40px;
  letter-spacing: 0;
}

.asset-management .asset-cta-button {
  background-color: transparent;
  color: var(--text-light);
  border: 1px solid var(--gold-primary);
  padding: 15px 32px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.asset-management .asset-cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.asset-management .asset-cta-button:hover {
  color: var(--dark-blue);
}

.asset-management .asset-cta-button:hover::before {
  width: 100%;
}

/* 介绍部分样式 */
.asset-management .asset-introduction {
  display: flex;
  flex-wrap: wrap;
  padding: var(--section-padding);
  background-color: #fafafa;
  position: relative;
  overflow: hidden;
}

.asset-management .subtle-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 20% 20%, var(--gold-light) 0%, transparent 15%),
    radial-gradient(circle at 80% 80%, var(--gold-light) 0%, transparent 15%);
  opacity: 0.4;
}

.asset-management .intro-content {
  flex: 1.15;
  min-width: 300px;
  padding-right: 40px;
  position: relative;
  z-index: 2;
}

.asset-management .intro-content h2 {
  font-size: 2.2rem;
  font-weight: 300;
  color: var(--dark-blue);
  margin-bottom: 25px;
  line-height: 1.2;
}

.asset-management .intro-content h2::first-letter {
  color: var(--gold-primary);
}

.asset-management .gold-separator {
  width: 50px;
  height: 2px;
  background-color: var(--gold-primary);
  margin-bottom: 25px;
  position: relative;
}

.asset-management .gold-separator::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 1px;
  background-color: var(--gold-light);
  top: 5px;
  left: 0;
}

.asset-management .intro-content p {
  font-size: 1rem;
  line-height: 1.7;
  color: var(--text-muted);
  margin-bottom: 25px;
  font-weight: 300;
}

.asset-management .highlighted-text {
  font-size: 1.2rem !important;
  font-style: italic;
  color: var(--dark-blue) !important;
  border-left: 2px solid var(--gold-primary);
  padding: 15px 20px !important;
  margin-top: 30px !important;
  font-weight: 300 !important;
  background: linear-gradient(to right, var(--gold-light), transparent 80%);
  border-radius: 0 4px 4px 0;
}

.asset-management .intro-image {
  flex: 0.85;
  min-width: 280px;
  position: relative;
  z-index: 2;
  align-self: center;
  margin-left: 20px;
  max-width: 450px;
}

.asset-management .intro-image::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid var(--gold-primary);
  top: -15px;
  left: -15px;
  z-index: 1;
}

.asset-management .intro-image img {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
}

/* 视觉分隔部分样式 */
.asset-management .visual-divider {
  height: 400px;
  position: relative;
  overflow: hidden;
}

.asset-management .parallax-image {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.asset-management .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(10, 25, 47, 0.9) 0%, rgba(10, 25, 47, 0.8) 100%);
}

.asset-management .content-container {
  position: relative;
  z-index: 10;
  width: 90%;
  max-width: 800px;
  text-align: center;
}

.asset-management .divider-content {
  padding: 30px;
  background: rgba(10, 25, 47, 0.85);
  backdrop-filter: blur(5px);
  border-left: 3px solid var(--gold-primary);
  position: relative;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transform: translateZ(0);
}

.asset-management .divider-content::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px solid rgba(178, 147, 102, 0.2);
  pointer-events: none;
}

.asset-management .divider-content h2 {
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--gold-primary);
  margin-bottom: 25px;
  line-height: 1.4;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.asset-management .divider-content .gold-separator {
  margin: 0 auto 25px auto;
  background-color: var(--gold-primary);
  opacity: 0.7;
}

.asset-management .divider-content p {
  font-size: 1rem;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 300;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* 服务卡片样式 */
.asset-management .services-section {
  padding: var(--section-padding);
  background-color: #fafafa;
  position: relative;
  overflow: hidden;
}

.asset-management .services-bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(var(--gold-light) 1px, transparent 1px),
    radial-gradient(var(--gold-light) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: 0 0, 25px 25px;
  opacity: 0.1;
  z-index: 1;
}

.asset-management .section-title {
  font-size: 2.2rem;
  font-weight: 300;
  color: var(--dark-blue);
  margin-bottom: 60px;
  text-align: center;
  position: relative;
}

.asset-management .section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background-color: var(--gold-primary);
}

.asset-management .section-title::before {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 1px;
  background-color: var(--gold-light);
  bottom: -20px;
}

.asset-management .services-container {
  margin-top: 50px;
  position: relative;
  z-index: 2;
}

.asset-management .service-item {
  margin-bottom: 80px;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: all 0.3s ease;
}

.asset-management .service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.asset-management .service-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, var(--gold-primary), var(--medium-blue));
  opacity: 0.7;
}

.asset-management .service-header {
  padding: 25px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

.asset-management .service-icon {
  font-size: 1.8rem;
  color: var(--gold-primary);
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--gold-light);
  transition: all 0.3s ease;
}

.asset-management .service-item:hover .service-icon {
  background: var(--gold-primary);
  color: white;
  transform: rotate(360deg);
}

.asset-management .service-header h3 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--dark-blue);
  margin: 0;
}

.asset-management .service-content {
  padding: 25px 30px;
}

.asset-management .service-content p {
  font-size: 0.95rem;
  line-height: 1.7;
  color: var(--text-muted);
  margin-bottom: 15px;
  font-weight: 300;
}

.asset-management .service-features {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.asset-management .service-features li {
  padding: 8px 0 8px 25px;
  position: relative;
  font-size: 0.95rem;
  line-height: 1.5;
  color: var(--text-muted);
  font-weight: 300;
}

.asset-management .service-features li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 15px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--gold-primary);
}

/* 联系我们部分样式 */
.asset-management .contact-section {
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-blue);
  overflow: hidden;
}

.asset-management .contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(10, 25, 47, 0.9) 0%, rgba(10, 25, 47, 0.95) 100%);
  z-index: 1;
}

.asset-management .contact-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(45deg, var(--medium-blue) 25%, transparent 25%),
    linear-gradient(-45deg, var(--medium-blue) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--medium-blue) 75%),
    linear-gradient(-45deg, transparent 75%, var(--medium-blue) 75%);
  background-size: 20px 20px;
  opacity: 0.02;
  z-index: 2;
}

.asset-management .contact-content {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0 20px;
  max-width: 700px;
}

.asset-management .contact-content h2 {
  font-size: 2rem;
  font-weight: 300;
  color: var(--text-light);
  margin-bottom: 25px;
  letter-spacing: 0;
}

.asset-management .contact-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
  font-weight: 300;
}

.asset-management .contact-button {
  background-color: transparent;
  color: var(--text-light);
  border: 1px solid var(--gold-primary);
  padding: 14px 32px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.asset-management .contact-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.asset-management .contact-button:hover {
  color: var(--dark-blue);
}

.asset-management .contact-button:hover::before {
  width: 100%;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .asset-management .asset-hero-content h2 {
    font-size: 3rem;
  }
  
  .asset-management .intro-content h2,
  .asset-management .section-title,
  .asset-management .contact-content h2 {
    font-size: 2rem;
  }
  
  .asset-management .service-header h3 {
    font-size: 1.4rem;
  }
}

@media (max-width: 992px) {
  .asset-management .asset-hero-content h1 {
    font-size: 1rem;
  }
  
  .asset-management .asset-hero-content h2 {
    font-size: 2.5rem;
  }
  
  .asset-management .asset-hero-content h3 {
    font-size: 1.2rem;
  }
  
  .asset-management .intro-content {
    padding-right: 0;
    margin-bottom: 40px;
  }
  
  .asset-management .intro-content h2 {
    font-size: 1.8rem;
  }
  
  .asset-management .visual-divider {
    height: 350px;
  }
  
  .asset-management .divider-content h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .asset-management .asset-hero-section {
    height: 80vh;
  }
  
  .asset-management .asset-hero-content h1 {
    font-size: 0.9rem;
    letter-spacing: 4px;
  }
  
  .asset-management .asset-hero-content h2 {
    font-size: 2rem;
  }
  
  .asset-management .asset-hero-content h3 {
    font-size: 1rem;
  }
  
  .asset-management .intro-content h2, 
  .asset-management .section-title, 
  .asset-management .contact-content h2 {
    font-size: 1.6rem;
  }
  
  .asset-management .service-header {
    padding: 20px;
  }
  
  .asset-management .service-content {
    padding: 20px;
  }
  
  .asset-management .service-header h3 {
    font-size: 1.3rem;
  }
  
  .asset-management .intro-image::before {
    display: none;
  }
}

@media (max-width: 576px) {
  .asset-management .asset-hero-content h1 {
    font-size: 0.8rem;
    letter-spacing: 3px;
  }
  
  .asset-management .asset-hero-content h2 {
    font-size: 1.8rem;
  }
  
  .asset-management .asset-hero-content h3 {
    font-size: 1rem;
  }
  
  .asset-management .asset-cta-button, 
  .asset-management .contact-button {
    padding: 12px 25px;
    font-size: 0.8rem;
  }
  
  .asset-management .intro-content h2, 
  .asset-management .section-title, 
  .asset-management .contact-content h2 {
    font-size: 1.5rem;
  }
  
  .asset-management .highlighted-text {
    font-size: 1rem !important;
    padding: 12px 15px !important;
  }
  
  .asset-management .service-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 1.3rem;
    margin-right: 15px;
  }
  
  .asset-management .service-header h3 {
    font-size: 1.2rem;
  }
  
  .asset-management .contact-content p {
    font-size: 0.95rem;
  }
} 