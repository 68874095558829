/* TenantRepresentative.css */
/* 全局样式设置 */
.tenant-representative {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  overflow-x: hidden;
  --gold-primary: #b29366;
  --gold-light: rgba(178, 147, 102, 0.15);
  --gold-medium: rgba(178, 147, 102, 0.5);
  --dark-blue: #0a192f;
  --medium-blue: #172a46;
  --light-blue: #1e355a;
  --text-dark: #2c3e50;
  --text-light: #f8f9fa;
  --text-muted: #7f8c8d;
  --section-padding: 90px 8%;
  --accent-gold: #b29366;
}

/* 英雄区域样式 */
.tenant-representative .hero-section {
  position: relative;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--dark-blue);
}

.tenant-representative .background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('../assets/images/teant_representative/001.jpeg');
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.tenant-representative .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, 
    rgba(10, 25, 47, 0.92) 0%, 
    rgba(23, 42, 70, 0.85) 50%, 
    rgba(10, 25, 47, 0.92) 100%);
  z-index: 1;
}

/* 创新: 办公室楼层平面图图案覆盖层 */
.tenant-representative .pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.06;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h100v100H0z' fill='none'/%3E%3Cpath fill='%23b29366' fill-opacity='0.6' d='M0 0h25v25H0zM25 0h25v25H25zM50 0h25v25H50zM75 0h25v25H75zM0 25h25v25H0zM50 25h25v25H50zM75 25h25v25H75zM0 50h25v25H0zM25 50h25v25H25zM50 50h25v25H50zM75 50h25v25H75zM0 75h25v25H0zM25 75h25v25H25zM50 75h25v25H50zM75 75h25v25H75z'/%3E%3Cpath fill='%23b29366' fill-opacity='0.6' d='M50 0h1v100h-1zM0 50h100v1H0z'/%3E%3C/svg%3E");
  z-index: 2;
}

/* 动画形状 */
.tenant-representative .animated-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.tenant-representative .shape {
  position: absolute;
  backdrop-filter: blur(3px);
  transform-origin: center;
  animation: float 15s infinite ease-in-out;
}

.tenant-representative .shape-1 {
  width: 40vmin;
  height: 40vmin;
  top: -10vmin;
  left: -5vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 2px;
  background: linear-gradient(145deg, rgba(10, 25, 47, 0.1), rgba(178, 147, 102, 0.05));
  box-shadow: 0 0 60px rgba(178, 147, 102, 0.05);
  animation-duration: 25s;
}

.tenant-representative .shape-2 {
  width: 30vmin;
  height: 30vmin;
  bottom: -5vmin;
  right: -5vmin;
  border: 1px solid rgba(178, 147, 102, 0.1);
  border-radius: 2px;
  background: linear-gradient(145deg, rgba(23, 42, 70, 0.1), rgba(10, 25, 47, 0.1));
  box-shadow: 0 0 40px rgba(178, 147, 102, 0.05);
  animation-delay: -5s;
  animation-duration: 20s;
}

.tenant-representative .shape-3 {
  width: 25vmin;
  height: 25vmin;
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 1px solid rgba(178, 147, 102, 0.08);
  border-radius: 2px;
  background: linear-gradient(145deg, rgba(178, 147, 102, 0.05), rgba(10, 25, 47, 0.05));
  opacity: 0.3;
  animation-delay: -10s;
  animation-duration: 18s;
}

/* 创新: 建筑线条装饰 */
.tenant-representative .blueprint-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.07;
  overflow: hidden;
}

.tenant-representative .blueprint-line {
  position: absolute;
  background: var(--gold-primary);
  opacity: 0.5;
}

.tenant-representative .blueprint-line-1 {
  width: 1px;
  height: 40%;
  top: 10%;
  left: 15%;
  transform: rotate(15deg);
}

.tenant-representative .blueprint-line-2 {
  width: 1px;
  height: 60%;
  top: 20%;
  right: 25%;
  transform: rotate(-20deg);
}

.tenant-representative .blueprint-line-3 {
  width: 60%;
  height: 1px;
  top: 25%;
  left: 20%;
  transform: rotate(10deg);
}

.tenant-representative .blueprint-line-4 {
  width: 40%;
  height: 1px;
  bottom: 30%;
  right: 10%;
  transform: rotate(-5deg);
}

/* 动画效果 */
@keyframes float {
  0% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
  25% {
    transform: rotate(3deg) translate(10px, 10px) scale(1.01);
  }
  50% {
    transform: rotate(0deg) translate(20px, 5px) scale(1);
  }
  75% {
    transform: rotate(-3deg) translate(5px, 15px) scale(0.99);
  }
  100% {
    transform: rotate(0deg) translate(0, 0) scale(1);
  }
}

/* 光效设计 */
.tenant-representative .light-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(178, 147, 102, 0.05) 0%, transparent 35%),
    radial-gradient(circle at 80% 80%, rgba(178, 147, 102, 0.06) 0%, transparent 35%),
    radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.02) 0%, transparent 50%);
  z-index: 2;
  opacity: 1;
}

.tenant-representative .hero-content {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tenant-representative .hero-content h1 {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 6px;
  color: var(--gold-primary);
  margin-bottom: 25px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 15px;
  display: inline-block;
}

.tenant-representative .hero-content h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 1px;
  background: var(--gold-primary);
}

.tenant-representative .hero-content h2 {
  font-size: 3.3rem;
  font-weight: 300;
  color: var(--text-light);
  margin-bottom: 30px;
  letter-spacing: 0;
  line-height: 1.2;
  background: linear-gradient(120deg, #ffffff, #e8e8e8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: 100%;
}

.tenant-representative .hero-content h3 {
  font-size: 1.4rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 40px;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.tenant-representative .hero-content h3::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, var(--gold-primary), transparent);
}

.tenant-representative .hero-button-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.tenant-representative .cta-button {
  background-color: transparent;
  color: var(--text-light);
  border: 1px solid var(--gold-primary);
  padding: 15px 32px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-top: 10px;
  display: inline-block;
  text-decoration: none;
}

.tenant-representative .cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.tenant-representative .cta-button:hover {
  color: var(--text-light);
}

.tenant-representative .cta-button:hover::before {
  width: 100%;
}

/* 介绍部分样式 */
.tenant-representative .intro-section {
  padding: var(--section-padding);
  background-color: #fafafa;
  position: relative;
  overflow: hidden;
}

.tenant-representative .intro-bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(var(--gold-light) 1px, transparent 1px),
    radial-gradient(var(--gold-light) 1px, transparent 1px);
  background-size: 40px 40px, 30px 30px;
  background-position: 0 0, 20px 20px;
  opacity: 0.2;
  z-index: 1;
}

.tenant-representative .intro-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.tenant-representative .intro-content {
  flex: 1;
  min-width: 300px;
  padding-right: 20px;
  position: relative;
}

.tenant-representative .intro-image {
  flex: 0.9;
  min-width: 320px;
  position: relative;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tenant-representative .intro-image-frame {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 85%;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.tenant-representative .intro-image-frame img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;
}

.tenant-representative .intro-image-frame:hover img {
  transform: scale(1.05);
}

/* 添加装饰性图标元素 */
.tenant-representative .intro-image::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  width: 80px;
  height: 80px;
  border-top: 3px solid var(--gold-primary);
  border-left: 3px solid var(--gold-primary);
  z-index: -1;
  opacity: 0.8;
}

.tenant-representative .intro-image::after {
  content: '';
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 80px;
  height: 80px;
  border-bottom: 3px solid var(--gold-primary);
  border-right: 3px solid var(--gold-primary);
  z-index: -1;
  opacity: 0.8;
}

/* 添加额外的图片装饰 */
.tenant-representative .intro-image-frame::before {
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  width: 70%;
  height: 70%;
  background: linear-gradient(135deg, transparent, var(--gold-light));
  z-index: -1;
  border-radius: 4px;
}

.tenant-representative .intro-image-frame::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 70%;
  height: 70%;
  background: linear-gradient(135deg, var(--gold-light), transparent);
  z-index: -1;
  border-radius: 4px;
}

.tenant-representative .section-title {
  font-size: 2.2rem;
  font-weight: 300;
  color: var(--dark-blue);
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;
  line-height: 1.3;
}

.tenant-representative .section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, var(--medium-blue), var(--gold-primary));
}

.tenant-representative .subsection-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--dark-blue);
  margin: 35px 0 15px;
  position: relative;
  display: inline-block;
  padding-left: 15px;
}

.tenant-representative .subsection-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: linear-gradient(to bottom, var(--gold-primary), var(--gold-light));
  border-radius: 2px;
}

.tenant-representative .subsection-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 40px;
  height: 2px;
  background: var(--gold-primary);
}

.tenant-representative .section-text {
  font-size: 1.05rem;
  line-height: 1.8;
  color: var(--text-muted);
  margin-bottom: 25px;
}

.tenant-representative .intro-quote {
  position: relative;
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-dark);
  font-style: italic;
  padding: 20px 25px;
  margin: 20px 0 30px;
  background-color: rgba(255, 255, 255, 0.7);
  border-left: 3px solid var(--gold-primary);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 0 4px 4px 0;
}

.tenant-representative .intro-quote p {
  margin: 0;
}

.tenant-representative .intro-quote::before {
  content: '"';
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 3rem;
  color: var(--gold-primary);
  opacity: 0.2;
  font-family: Georgia, serif;
}

.tenant-representative .highlight-text {
  border-left: 3px solid var(--gold-primary);
  padding-left: 20px;
  font-style: italic;
  color: var(--text-dark);
  margin: 30px 0;
  background-color: var(--gold-light);
  padding: 20px;
  border-radius: 0 4px 4px 0;
  font-size: 1.1rem;
  line-height: 1.7;
  font-weight: 500;
}

/* 专业领域部分 */
.tenant-representative .expertise-section {
  padding: var(--section-padding);
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.tenant-representative .expertise-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(10, 25, 47, 0.03) 0%, transparent 40%),
              linear-gradient(45deg, transparent 60%, rgba(178, 147, 102, 0.05) 100%);
  z-index: 1;
}

.tenant-representative .expertise-container {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
}

.tenant-representative .expertise-header {
  text-align: center;
  margin-bottom: 60px;
}

.tenant-representative .expertise-header .section-title {
  display: inline-block;
}

.tenant-representative .expertise-header .section-title::after {
  left: 50%;
  transform: translateX(-50%);
}

.tenant-representative .expertise-description {
  max-width: 800px;
  margin: 0 auto 50px;
  text-align: center;
}

/* 创新：高级专业领域展示 */
.tenant-representative .expertise-showcase {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.tenant-representative .expertise-title-row {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.tenant-representative .expertise-main-title {
  font-size: 2rem;
  font-weight: 300;
  color: var(--dark-blue);
  position: relative;
  display: inline-block;
  padding: 15px 40px;
  margin: 0 auto;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid var(--gold-primary);
  z-index: 3;
}

.tenant-representative .expertise-main-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  transform: skewX(-5deg);
  z-index: -1;
}

.tenant-representative .expertise-title-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent 0%, 
    var(--gold-light) 20%, 
    var(--gold-primary) 50%,
    var(--gold-light) 80%,
    transparent 100%);
  z-index: 2;
}

.tenant-representative .expertise-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  perspective: 1000px;
  padding: 10px 0;
}

.tenant-representative .expertise-card {
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: visible;
  transition: all 0.5s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 420px;
  margin-top: 20px;
}

.tenant-representative .expertise-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.tenant-representative .expertise-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, var(--gold-primary), var(--gold-light));
  z-index: 2;
}

.tenant-representative .expertise-card-image {
  height: 180px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.tenant-representative .expertise-card-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to top, rgba(10, 25, 47, 0.7), transparent);
}

.tenant-representative .office-image {
  background-image: url('../assets/images/teant_representative/002.jpeg');
}

.tenant-representative .retail-image {
  background-image: url('../assets/images/teant_representative/003.jpeg');
}

.tenant-representative .industrial-image {
  background-image: url('../assets/images/teant_representative/004.jpeg');
}

.tenant-representative .expertise-card-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  z-index: 2;
}

.tenant-representative .expertise-card-title {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--dark-blue);
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 15px;
}

.tenant-representative .expertise-card-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background: var(--gold-primary);
}

.tenant-representative .expertise-card-text {
  font-size: 1rem;
  line-height: 1.7;
  color: var(--text-muted);
  flex-grow: 1;
}

.tenant-representative .expertise-card-icon {
  position: absolute;
  top: -25px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  color: var(--gold-primary);
  font-size: 1.3rem;
  z-index: 3;
  transition: all 0.3s ease;
  border: 1px solid var(--gold-light);
}

.tenant-representative .expertise-card:hover .expertise-card-icon {
  transform: rotate(360deg);
}

.tenant-representative .expertise-card-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 25px;
  background: transparent;
  border: 1px solid var(--gold-primary);
  color: var(--dark-blue);
  font-size: 0.9rem;
  text-decoration: none;
  transition: all 0.3s ease;
  border-radius: 4px;
  text-align: center;
}

.tenant-representative .expertise-card-button:hover {
  background: var(--gold-primary);
  color: white;
}

/* 响应式调整 */
@media (max-width: 992px) {
  .tenant-representative .expertise-cards {
    grid-template-columns: repeat(1, 1fr);
    max-width: 500px;
    margin: 0 auto;
  }
  
  .tenant-representative .expertise-card {
    height: auto;
  }
  
  .tenant-representative .expertise-main-title {
    font-size: 1.8rem;
  }
}

/* 相关服务部分 */
.tenant-representative .services-section {
  padding: var(--section-padding);
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.tenant-representative .services-bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(var(--gold-light) 1px, transparent 1px),
    radial-gradient(var(--gold-light) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: 0 0, 25px 25px;
  opacity: 0.2;
  z-index: 1;
}

.tenant-representative .services-container {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
}

.tenant-representative .services-title {
  text-align: center;
  margin-bottom: 60px;
}

.tenant-representative .services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.tenant-representative .service-item {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
}

.tenant-representative .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.tenant-representative .service-content {
  padding: 25px;
}

.tenant-representative .service-icon {
  width: 60px;
  height: 60px;
  background: var(--gold-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: var(--gold-primary);
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.tenant-representative .service-item:hover .service-icon {
  background: var(--gold-primary);
  color: white;
}

.tenant-representative .service-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--dark-blue);
  margin-bottom: 15px;
}

.tenant-representative .service-text {
  font-size: 0.95rem;
  color: var(--text-muted);
  line-height: 1.7;
}

/* 创新：两列布局的服务项 */
.tenant-representative .dual-service-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  grid-column: 1 / -1;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.tenant-representative .dual-service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.tenant-representative .dual-service-image {
  position: relative;
  overflow: hidden;
}

.tenant-representative .dual-service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;
}

.tenant-representative .dual-service-item:hover .dual-service-image img {
  transform: scale(1.05);
}

.tenant-representative .dual-service-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 联系部分样式 */
.tenant-representative .contact-section {
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-blue);
  overflow: hidden;
}

.tenant-representative .contact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(10, 25, 47, 0.9) 0%, rgba(10, 25, 47, 0.95) 100%);
  z-index: 1;
}

.tenant-representative .contact-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(45deg, var(--medium-blue) 25%, transparent 25%),
    linear-gradient(-45deg, var(--medium-blue) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--medium-blue) 75%),
    linear-gradient(-45deg, transparent 75%, var(--medium-blue) 75%);
  background-size: 20px 20px;
  opacity: 0.02;
  z-index: 2;
}

.tenant-representative .contact-content {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0 20px;
  max-width: 700px;
}

.tenant-representative .contact-content h2 {
  font-size: 2rem;
  font-weight: 300;
  color: var(--text-light);
  margin-bottom: 25px;
  letter-spacing: 0;
}

.tenant-representative .contact-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
  font-weight: 300;
}

.tenant-representative .contact-button {
  background-color: transparent;
  color: var(--text-light);
  border: 1px solid var(--gold-primary);
  padding: 14px 32px;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  text-decoration: none;
}

.tenant-representative .contact-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--gold-primary);
  transition: all 0.3s ease;
  z-index: -1;
}

.tenant-representative .contact-button:hover {
  color: var(--text-light);
}

.tenant-representative .contact-button:hover::before {
  width: 100%;
}

/* 右侧信息点样式 */
.tenant-representative .intro-side-info {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tenant-representative .info-item {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 15px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-left: 3px solid var(--gold-primary);
}

.tenant-representative .info-item:hover {
  transform: translateX(5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
}

.tenant-representative .info-icon {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--gold-light);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gold-primary);
  font-size: 1rem;
}

.tenant-representative .info-text {
  font-size: 0.9rem;
  color: var(--text-dark);
  line-height: 1.4;
  font-weight: 500;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .tenant-representative .hero-content h2 {
    font-size: 3rem;
  }
  
  .tenant-representative .section-title {
    font-size: 2rem;
  }
  
  .tenant-representative .chart-items {
    gap: 20px;
  }
  
  .tenant-representative .chart-item {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 992px) {
  .tenant-representative .hero-content h1 {
    font-size: 1rem;
  }
  
  .tenant-representative .hero-content h2 {
    font-size: 2.5rem;
  }
  
  .tenant-representative .hero-content h3 {
    font-size: 1.2rem;
  }
  
  .tenant-representative .section-title {
    font-size: 1.8rem;
  }
  
  .tenant-representative .subsection-title {
    font-size: 1.3rem;
  }
  
  .tenant-representative .intro-container {
    flex-direction: column;
  }
  
  .tenant-representative .intro-content {
    flex: 1;
    padding-right: 0;
  }
  
  .tenant-representative .intro-image {
    flex: 1;
    margin-top: 30px;
  }
  
  .tenant-representative .intro-side-info {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .tenant-representative .info-item {
    flex: 1;
    min-width: 200px;
    max-width: 300px;
  }
  
  .tenant-representative .chart-center {
    width: 180px;
    height: 180px;
  }
  
  .tenant-representative .chart-center-inner {
    width: 160px;
    height: 160px;
  }
  
  .tenant-representative .dual-service-item {
    grid-template-columns: 1fr;
  }
  
  .tenant-representative .dual-service-image {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .tenant-representative .hero-section {
    height: 80vh;
  }
  
  .tenant-representative .hero-content h1 {
    font-size: 0.9rem;
    letter-spacing: 4px;
  }
  
  .tenant-representative .hero-content h2 {
    font-size: 2.2rem;
  }
  
  .tenant-representative .hero-content h3 {
    font-size: 1.1rem;
  }
  
  .tenant-representative .section-title {
    font-size: 1.6rem;
  }
  
  .tenant-representative .subsection-title {
    font-size: 1.2rem;
  }
  
  .tenant-representative .section-text {
    font-size: 1rem;
  }
  
  .tenant-representative .chart-item {
    flex: 0 0 100%;
  }
}

@media (max-width: 576px) {
  .tenant-representative .hero-content h1 {
    font-size: 0.8rem;
    letter-spacing: 3px;
  }
  
  .tenant-representative .hero-content h2 {
    font-size: 1.8rem;
  }
  
  .tenant-representative .hero-content h3 {
    font-size: 1rem;
  }
  
  .tenant-representative .cta-button {
    padding: 12px 25px;
    font-size: 0.8rem;
  }
  
  .tenant-representative .section-title {
    font-size: 1.5rem;
  }
  
  .tenant-representative .subsection-title {
    font-size: 1.1rem;
  }
  
  .tenant-representative .intro-image::before,
  .tenant-representative .intro-image::after {
    width: 40px;
    height: 40px;
  }
  
  .tenant-representative .contact-content h2 {
    font-size: 1.7rem;
  }
  
  .tenant-representative .contact-content p {
    font-size: 1rem;
  }
  
  .tenant-representative .contact-button {
    padding: 12px 30px;
    font-size: 0.9rem;
  }
  
  .tenant-representative .intro-side-info {
    flex-direction: column;
  }
  
  .tenant-representative .info-item {
    flex: 1;
    min-width: auto;
    max-width: none;
  }
} 